import React, { Component, useEffect } from "react";
import {
  Router,
  Route,
  Switch,
  withRouter,
  useLocation,
  useRouteMatch,
  matchPath,
} from "react-router-dom";
import history from "./History";
import Navigation from "./components/Navigation";
import * as ROUTES from "./constants/routes";
import LandingPage from "./components/LandingPage";
import TermsOfService from "./components/TermsOfService";
import PrivacyPage from "./components/PrivacyPage";
import CarePackagePage from "./components/CarePackagePage";
import SignInPage from "./components/SignIn";
import Home from "./components/Home/Home";
import SignUpPage from "./components/SignUp";
import Download from "./components/Download";
import PasswordForgetPage from "./components/PasswordForget";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";
import TrackingPage from "./components/TrackingPage";
import SecretSanta from "./components/SecretSanta";
import SecretSantaTeams from "./components/SecretSantaTeams";
import { connect } from "react-redux";
import { checkAuthentication } from "./components/actions/AuthActions";
import API from "./components/More/API";
import ShopIntegrations from "./components/More/ShopIntegrations";
import Shopify from "./components/More/Shopify";
import Contact from "./components/More/Contact";
import AccountInfoForm from "./components/Home/UserDetails/AccountInfoForm";
import VerifyEmail from "./components/VerifyEmail";
import AccountForms from "./components/Home/AccountForms";
import UpdatePasswordPage from "./components/UpdatePassword";
import BusinessIntro from "./components/BusinessIntro";
import BusinessIntroFAQs from "./components/BusinessIntroFAQs";
import KQLanding from "./components/KQLanding";

const TrackingPageRoute = withRouter((props) => <TrackingPage {...props} />);
const ScrollToTop = withRouter((props) => {
  const { pathname } = useLocation();
  const match = useRouteMatch({
    path: ["/", "/more/*"],
    exact: true,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      left: 0,
    });
  }, [pathname]);

  return null;
});

const RouterComponent = (props) => {
  const { pathname } = history.location;
  const matchLanding = matchPath(pathname, {
    path: ROUTES.LANDING,
    exact: true,
  });

  useEffect(() => {
    // ? Trailing slash causes issues in route checking so remove the trailing slash and reload the page
    // TODO: Find a better way to either check or fix the trailing slash
    if (!matchLanding && pathname[pathname.length - 1] === "/") {
      history.replace(pathname.substring(0, pathname.length - 1));
      window.location.reload();
    }
    props.checkAuthentication();
  }, []);

  return (
    <Router history={history}>
      <ScrollToTop />
      <Navigation />
      <Switch>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route exact path={ROUTES.INTRO} component={BusinessIntro} />
        <Route exact path={ROUTES.INTRO_FAQ} component={BusinessIntroFAQs} />
        <Route path={ROUTES.TERMS} component={TermsOfService} />
        <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
        <Route path={ROUTES.CARE_PACKAGE} component={CarePackagePage} />
        <Route path={ROUTES.INVITES} component={LandingPage} />
        <Route exact path={ROUTES.SECRET_SANTA} component={SecretSanta} />
        <Route
          path={ROUTES.SECRET_SANTA[7] + "/:exchangeId/:exchangeName"}
          component={SecretSanta}
        />
        <Route path={ROUTES.TEAM_GIFTS} component={SecretSantaTeams} />
        <Route
          path={ROUTES.TRACKING + "/:provider/:trackingNumber"}
          component={TrackingPageRoute}
        />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
        {/*TODO: Change this user check to a less hacky method*/}
        <Route path={ROUTES.HOME} component={Home} />
        {/*<Route path={ROUTES.SIGN_UP} component={SignUpPage}/>*/}
        <Route path={ROUTES.DOWNLOAD} component={Download} />
        <Route path={ROUTES.CONTACT_US} component={Contact} />
        <Route path={ROUTES.MORE_API} component={API} />
        <Route path={ROUTES.MORE_SHOP} component={ShopIntegrations} />
        <Route path={ROUTES.MORE_SHOPIFY} component={Shopify} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.UPDATE_PASSWORD} component={UpdatePasswordPage} />
        <Route path={ROUTES.ACCOUNT_FORMS} component={AccountForms} />
        <Route path={ROUTES.KQ_LANDING} component={KQLanding} />
        <Route component={ErrorPage} />
      </Switch>
      <Footer />
    </Router>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, { checkAuthentication })(
  RouterComponent,
);
