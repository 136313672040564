import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Card, Dropdown } from "react-bootstrap";
import { auth, functions } from "../../../services/firebase";
import { connect } from "react-redux";
import { addUserCard, getUserDetails } from "../../actions";
import instance from "../../../services/axiosConfig";
import PhoneInput from "react-phone-input-2";
import {
  CREDIT_CARD,
  MPESA,
  PAYSTACK,
  SAFARICOM,
  STRIPE,
} from "../../../constants/types";
import MessageBlock from "../common/MessageBlock";

const CardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [formType, setFormType] = useState(STRIPE);

  useEffect(() => {
    if (props.country && props.country?.toString().toLowerCase() === "ke") {
      setFormType(MPESA);
    }
  }, []);

  useEffect(() => {
    setError("");
  }, [formType, phone]);

  const saveStripeCard = async () => {
    const billingDetails = {
      email: props.info?.email || auth()?.currentUser?.email || "Unknown",
    };
    const element = elements.getElement(CardElement);

    props.addUserCard(
      {
        provider: STRIPE,
        stripe: stripe,
        element: element,
        billingDetails: billingDetails,
        data: {
          payment_method_type: CREDIT_CARD,
          // id_in_provider: setupIntent.payment_method,
          details: {},
        },
      },
      (id) => {
        props.getUserDetails(() => {
          props.onFinish(id);
        });
      },
    );
  };

  const saveMPESACard = async () => {
    //remove prefixed '0' if any and add '+' since the PhoneInput component leaves it out
    let validatedPhone = phone;
    if (validatedPhone.startsWith(`2540`)) {
      validatedPhone = validatedPhone.replace(`2540`, "254");
    }

    if (!(validatedPhone.startsWith("254") && validatedPhone.length === 12)) {
      setError("Please enter a valid number");
      return;
    }

    let formattedPhone = "+" + validatedPhone;

    props.addUserCard(
      {
        payment_method_type: MPESA,
        details: { mobile_money_phone_number: formattedPhone },
        provider: SAFARICOM,
      },
      (id) => {
        props.getUserDetails(() => {
          props.onFinish(id);
        });
      },
    );
  };

  const renderStripeForm = () => {
    return (
      <div className="stripe-card-form">
        <CardElement
          className="card-field"
          options={{
            hidePostalCode: true,
            style: {
              base: {
                iconColor: "#009e89",
                color: "#000",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                "::placeholder": {
                  color: "#c2c2c2",
                },
              },
              invalid: {
                iconColor: "#dc3545",
                color: "#dc3545",
              },
            },
          }}
        />
        <div className="d-flex flex-row justify-content-between">
          <button
            onClick={saveStripeCard}
            disabled={props.loading}
            className={`m-auto p-0 ${props.cancleButton ? "mr-3" : ""}`}
          >
            {props.loading ? (
              <div className="spinner-border text-dark m-auto" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Save"
            )}
          </button>
          {props.cancleButton}
        </div>
      </div>
    );
  };

  const renderMPESAForm = () => {
    return (
      <div className={"paystack-card-form"}>
        <PhoneInput
          country={"ke"}
          className={""}
          value={phone}
          onChange={(phone, country) => {
            setPhone(phone);
          }}
          countryCodeEditable={false}
          onlyCountries={["ke"]}
          placeholder={"Enter your phone number"}
          isValid={(phone, country) => {
            return (
              (phone.startsWith("2547") && phone.length === 12) ||
              (phone.startsWith("25407") && phone.length === 13)
            );
          }}
        />
        <div className="d-flex flex-row justify-content-between">
          <button
            onClick={saveMPESACard}
            disabled={props.loading}
            className={`m-auto p-0 ${props.cancleButton ? "mr-3" : ""}`}
          >
            {props.loading ? (
              <div className="spinner-border text-dark m-auto" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Save"
            )}
          </button>
          {props.cancleButton}
        </div>
      </div>
    );
  };

  const renderCardForm = () => {
    return (
      <div className={"card-section last"}>
        {props.errors && <MessageBlock type={"error"} message={props.errors} />}
        {error && <MessageBlock type={"error"} message={error} />}
        <label className={"col-12 p-0"}>
          Payment Method
          <Dropdown className={"type-switch "}>
            <Dropdown.Toggle>
              {formType === STRIPE ? "Credit Card" : "MPESA"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setFormType(STRIPE)}
                disabled={formType === STRIPE}
              >
                Credit Card
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setFormType(MPESA)}
                disabled={formType === MPESA}
              >
                MPESA
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </label>
        <div>
          {formType === STRIPE ? renderStripeForm() : renderMPESAForm()}
        </div>
      </div>
    );
  };

  return <div>{renderCardForm()}</div>;
};

const mapStateToProps = ({ userDetails, applicationContext }) => ({
  cardList: userDetails.cards,
  info: userDetails.info,
  loading: userDetails.loading,
  errors: userDetails.errors,
  country: applicationContext.context.country,
});

export default connect(mapStateToProps, { getUserDetails, addUserCard })(
  CardForm,
);
