import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  clearLabelSearch,
  getAllLabels,
  getLabelsForChart,
  selectLabel,
} from "../actions/LabelActions";
import moment from "moment";
import "../../scss/dashboard.scss";
import { Link, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { ReactComponent as DashLabelIcon } from "../../assets/dash-label-icon.svg";
import { ReactComponent as DashCostIcon } from "../../assets/dash-cost-icon.svg";
import { ReactComponent as DashOrderIcon } from "../../assets/dash-order-icon.svg";
import { ReactComponent as DashTransitIcon } from "../../assets/dash-transit-icon.svg";
import { ReactComponent as VideoIcon } from "../../assets/other-videos-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/other-help-icon.svg";
import {
  FiBookmark,
  FiDownload,
  FiMoreVertical,
  FiPlus,
  FiX,
  FiInfo,
} from "react-icons/fi";
import { FaSortDown } from "react-icons/fa";
import LabelList from "./Label/LabelList";
import context from "react-bootstrap/esm/AccordionContext";
import OrdersList from "./Orders/OrdersList";
import { useCookies } from "react-cookie";
import shopifyLogo from "../../assets/shopify-logo-icon.svg";
import history from "../../History";
import {
  closeProfileCompletion,
  setApplicationContext,
} from "../actions/ApplicationContextActions";
import ProgressCircle from "./common/ProgressCircle";
import CreateShipmentImg from "../../assets/dash-create-shipment.png";
import WelcomeTour from "./WelcomeTour";
import { getAccountInfo, getOrders, setOnboarded } from "../actions";
import { ReactComponent as EmptyLabels } from "../../assets/bookmark-search.svg";

const Dashboard = (props) => {
  const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

  const [days, setDays] = useState(7);
  const [title, setTitle] = useState("Last 7 Days");
  const [graphTitles, setGraphTitles] = useState([]);
  const [graphNums, setGraphNums] = useState([]);
  const [trackingTitle, setTrackingTitle] = useState("Tracking Status");
  const [showWelcomeTour, setShowWelcomeTour] = useState(true);

  const { pathname } = useLocation();

  const [cookies] = useCookies();

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const labelData = [];
  let labelDate = [];

  const getGraphData = (num) => {
    setGraphTitles([]);
    setGraphNums([]);
    let currentDate = new Date(Date.now());
    switch (num) {
      case 7:
        populateGraph(7, currentDate, 7);
        break;
      case 15:
        populateGraph(7, currentDate, 14);
        break;
      case 30:
        populateGraph(6, currentDate, 30);
        break;
      case 90:
        populateGraph(6, currentDate, 90);
        break;
      default:
        return true;
    }
  };
  const getTableRecent = () => {
    const list = props.labels.length > 0 ? props.labels?.slice(0, 3) : [];
    return list;
  };

  const getGraphColor = (cookie, hover) => {
    if (cookie) {
      if (hover) return "#F2B821";
      return "#FFD97660";
    } else {
      if (hover) return "#1E8B7C";
      return "#C7E2DF";
    }
  };

  useEffect(() => {
    getGraphData(days);
  }, [props.graphLabels]);

  const populateGraph = (dataPoints, currentDate, num) => {
    const { graphLabels: graphData } = props;

    let startDate = new Date(currentDate);
    startDate.setTime(startDate.getTime() - (num - 1) * ONE_DAY_IN_MILLIS);
    let endDate = new Date(currentDate);
    endDate.setTime(endDate.getTime() - (num - 1) * ONE_DAY_IN_MILLIS);

    let totalLabels = 0;

    for (let i = 0; i < dataPoints; i++) {
      for (let j = 0; j < num / dataPoints; j++) {
        if (graphData[moment(endDate).format("MM/DD/YYYY")]?.label_count)
          totalLabels +=
            graphData[moment(endDate).format("MM/DD/YYYY")].label_count;
        // ! When setDate resets back to 1, the month doesn't change
        endDate.setTime(endDate.getTime() + ONE_DAY_IN_MILLIS);
      }
      endDate.setTime(endDate.getTime() - ONE_DAY_IN_MILLIS);

      labelData.push(totalLabels);

      if (moment(startDate).format("MM/DD") === moment(endDate).format("MM/DD"))
        labelDate.push(moment(startDate).format("MM/DD"));
      else
        labelDate.push(
          moment(startDate)
            .format("MM/DD")
            .concat(` - ${moment(endDate).format("MM/DD")}`),
        );

      totalLabels = 0;

      startDate.setTime(
        startDate.getTime() + (num / dataPoints) * ONE_DAY_IN_MILLIS,
      );
      endDate.setTime(startDate.getTime());
    }
    setGraphNums(labelData);
    setGraphTitles(labelDate);
  };

  const chartProcessing = (labels, days) => {
    if (labels.length && labels.length > 0) {
      let eligible = [];
      for (let i = 0; i < labels.length; i++) {
        let date = new Date(labels[i].created);
        let diffTime = Date.now() - date.getTime();
        let difference = (diffTime / (1000 * 3600 * 24)).toFixed(0);
        if (difference <= days) {
          eligible.push(labels[i]);
        }
      }
      return eligible;
    }
  };

  useEffect(() => {
    setDays(7);
    setTitle("Last 7 Days");
    props.getAllLabels();
    setTimeout(props.getOrders(null, props.pageSize), 1000);
    props.getLabelsForChart(getDaysBehind(7));
    getGraphData(7);
    if (Object.keys(props.userInfo).length === 0) {
      props.getAccountInfo();
    }
  }, []);

  chartProcessing(props.labels, 7);

  const handleToggle = (num) => {
    if (num === 7) {
      setTitle("Last 7 Days");
    } else if (num === 15) {
      setTitle("Last 2 Weeks");
    } else if (num === 30) {
      setTitle("Last Month");
    } else if (num === 90) {
      setTitle("Last 3 Months");
    }
  };

  const handleTrackingTitle = (tracking) => {
    switch (tracking) {
      case "status":
        setTrackingTitle("Tracking Status");
        break;
      case "delivered":
        setTrackingTitle("Delivered");
        break;
      case "transit":
        setTrackingTitle("In Transit");
        break;
      case "pre_transit":
        setTrackingTitle("Pre Transit");
        break;
      case "returned":
        setTrackingTitle("Returned");
        break;
      case "failure":
        setTrackingTitle("Failure");
        break;
      default:
        return true;
    }
  };

  const getDaysBehind = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().slice(0, 10);
  };

  const getOrdersRecent = () => {
    const list = props.orders.length > 0 ? props.orders?.slice(0, 3) : [];
    return list;
  };

  const getLabelsCount = () => {
    let count = 0;
    Object.values(props.graphLabels).forEach((item) => {
      count += item?.label_count ? parseInt(item.label_count) : 0;
    });
    return count;
  };

  const getLabelsInTransitCount = () => {
    let count = 0;
    Object.values(props.graphLabels).forEach((item) => {
      count += item?.transit_count ? parseInt(item.transit_count) : 0;
    });
    return count;
  };

  const calculateProfilePercentage = () => {
    const complete = [false, false, false, false];

    if (
      props.userInfo &&
      props.userInfo.full_name &&
      props.userInfo.full_name.toString().trim() !== "" &&
      props.userInfo.phone_number &&
      props.userInfo.phone_number.toString().trim() !== "" &&
      props.userInfo.business_name &&
      props.userInfo.business_name.toString().trim() !== "" &&
      props.userInfo.business_website &&
      props.userInfo.business_website.toString().trim() !== "" &&
      props.userInfo.monthly_avg_shipment &&
      props.userInfo.monthly_avg_shipment.toString().trim() !== ""
    ) {
      complete[0] = true;
    }
    if (
      props.businessDetails &&
      Object.keys(props.businessDetails).length > 0
    ) {
      if (
        props.businessDetails.country &&
        props.businessDetails.country.toString().trim() !== "" &&
        props.businessDetails.bank_name &&
        props.businessDetails.bank_name.toString().trim() !== "" &&
        props.businessDetails.account_number &&
        props.businessDetails.account_number.toString().trim() !== "" &&
        props.businessDetails.account_name &&
        props.businessDetails.account_name.toString().trim() !== ""
      ) {
        complete[1] = true;
      }
      if (
        props.businessDetails.document_name &&
        props.businessDetails.document_type
      ) {
        complete[2] = true;
      }
      if (props.businessDetails?.tax_number) {
        complete[3] = true;
      }
    }

    return complete;
  };

  const checkEmptyGraph = () => {
    if (Object.keys(props.graphLabels).length === 0) return true;

    const sortedDates = Object.keys(props.graphLabels).sort((date1, date2) => {
      return new Date(date2) - new Date(date1);
    });
    let latestLabelDate = sortedDates[0];
    return moment(latestLabelDate).isBefore(
      moment().subtract(days - 1, "days"),
      "day",
    );
  };

  const DashStatItem = (props) => {
    return (
      <div className="dash-card stat-item d-flex flex-row justify-content-start">
        {props.icon}
        <div className="d-flex flex-column align-items-start">
          <p className="title mb-0">{props.title}</p>
          <h4>{props.stat}</h4>
        </div>
      </div>
    );
  };

  const renderCompleteProfileMessage = () => {
    const complete = calculateProfilePercentage();
    if (!complete[0]) {
      return (
        <div>
          <div>
            <h3>Don’t forget to complete your profile!</h3>
            <p>
              You need to verify your business to unlock the full ShipShap
              experience.{" "}
            </p>
          </div>
          <button
            onClick={() => {
              props.setApplicationContext(
                { returnURL: pathname, actionType: "edit" },
                () => {
                  history.push(ROUTES.UPDATE_ACCOUNT_DETAILS);
                },
              );
            }}
          >
            Add Profile Details
          </button>
        </div>
      );
    } else if (!complete[1] || !complete[2] || !complete[3]) {
      return (
        <div>
          <div>
            <h3>Don’t forget to complete your profile!</h3>
            <p>
              You need to verify your business to unlock the full ShipShap
              experience.{" "}
            </p>
          </div>
          <button
            onClick={() => {
              props.setApplicationContext(
                { returnURL: pathname, actionType: "edit" },
                () => {
                  history.push(ROUTES.BANK_ACCOUNT_DETAILS);
                },
              );
            }}
          >
            Verify Business
          </button>
        </div>
      );
    }
    return (
      <div>
        <div>
          <h3>We are processing your documents</h3>
          <p>
            Please allow 24-48 hours for us to process your information.
            <br />
            If you have any questions{" "}
            <Link to={ROUTES.CONTACT_US}>Contact Us.</Link>
          </p>
        </div>
      </div>
    );
  };

  const renderProfileCompletePercentage = () => {
    const complete = calculateProfilePercentage();
    let percent = 0;
    complete.forEach((item) => {
      if (item === true) {
        percent += 25;
      }
    });

    return (
      <>
        <div
          className={
            "d-none d-lg-flex flex-column justify-content-center align-items-center"
          }
        >
          <div className={"completion-percentage"}>
            <ProgressCircle
              progress={percent}
              label={" "}
              spinnerMode={false}
              size={130}
            />
          </div>
          <div
            className={
              "d-flex flex-row align-items-center justify-content-start"
            }
          >
            <p className={"mb-0"}>Profile Completeness</p>
            <OverlayTrigger
              key={"business-details-missing"}
              placement="right"
              overlay={
                <Tooltip
                  id="business-details-missing-tooltip"
                  className={"business-details-missing-tooltip"}
                >
                  You are seeing this because you have missing information for
                  your business details
                </Tooltip>
              }
              delayShow={300}
              delayHide={150}
            >
              <FiInfo className={"warning"} />
            </OverlayTrigger>
          </div>
        </div>
        <div className={"d-flex d-lg-none flex-column"}>
          <div
            className={
              "d-flex flex-row justify-content-between align-items-center"
            }
          >
            <h2 className={"percent-value"}>{percent}%</h2>
            <div
              className={
                "d-flex flex-row align-items-center justify-content-start"
              }
            >
              <p className={"mb-0"}>Profile Completeness</p>
              <OverlayTrigger
                key={"business-details-missing"}
                placement="left"
                overlay={
                  <Tooltip
                    id="business-details-missing-tooltip"
                    className={"business-details-missing-tooltip"}
                  >
                    You are seeing this because you have missing information for
                    your business details
                  </Tooltip>
                }
                delayShow={300}
                delayHide={150}
              >
                <FiInfo className={"warning"} />
              </OverlayTrigger>
            </div>
          </div>
          <div className={"progress-bar-track"}>
            <div
              className={"progress-bar"}
              style={{ width: `${percent}%` }}
            ></div>
          </div>
        </div>
      </>
    );
  };

  const renderWelcomeTour = () => {
    return (
      <div className="modal">
        <div className="backdrop"></div>
        <div className="modal-content welcome-tour">
          {/*{props.userDetailsError&&<p>{props.userDetailsError}</p>}*/}
          <WelcomeTour
            fullName={props.userInfo.full_name}
            setOnboarded={() => {
              setShowWelcomeTour(false);
              props.setOnboarded();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`dashboard col-12 ${cookies.is_test ? "test-mode" : "live-mode"}`}
    >
      {props.businessDetails &&
        !props.businessDetails.is_verified &&
        props.context.showProfileCompletion && (
          <div className={"dash-profile-completion"}>
            <div className={"completion-content"}>
              {renderProfileCompletePercentage()}
            </div>
            {renderCompleteProfileMessage()}
            {calculateProfilePercentage().every((item) => item === true) && (
              <a
                className={"close-profile-completion"}
                onClick={() => props.closeProfileCompletion()}
              >
                <FiX size={25} />
              </a>
            )}
          </div>
        )}
      {!props.loading && props.labels.length === 0 && (
        <div
          className={
            "dash-card dash-create-label-banner d-none d-lg-flex flex-row "
          }
        >
          <div className={"col-6"}>
            <p className={"welcome-message"}>
              Welcome {props.userInfo.full_name} 🎉
            </p>
            <div className={"d-flex flex-column justify-content-start"}>
              <h2>Fulfill your first order!</h2>
              <p></p>
              <Link
                to={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
                className="ship-label-button d-none d-lg-flex"
                onClick={() => {
                  props.setApplicationContext({ returnURL: pathname });
                }}
              >
                Create A Shipping Label
              </Link>
            </div>
          </div>
          <div className={"col-6 p-0"}>
            <img src={CreateShipmentImg} />
          </div>
        </div>
      )}
      <div className="dash-stats col-12 ">
        <DashStatItem
          icon={<DashLabelIcon />}
          title="Number of Shipments"
          stat={getLabelsCount()}
        />
        <DashStatItem
          icon={<DashTransitIcon />}
          title="In Transit"
          stat={getLabelsInTransitCount()}
        />
      </div>
      <div className="settings-card dash-orders">
        <div className="card-section head">
          <h3>Recent Orders</h3>
        </div>
        <div className="card-section last">
          {props.storesLoading ? (
            <Spinner animation="border" role="status" />
          ) : props.orders?.length === 0 ? (
            <div className="empty">
              <EmptyLabels />
              <p>No Orders Found</p>
              <Link to={ROUTES.HOME + ROUTES.STORES}>
                <button>
                  {props.stores?.length === 0
                    ? "Connect a store"
                    : "No orders Found"}
                </button>
              </Link>
            </div>
          ) : (
            <OrdersList list={getOrdersRecent()} recent />
          )}
        </div>
      </div>
      <div className="dash-graphs d-flex flex-column flex-lg-row justify-content-between">
        <div className="settings-card labels-stat">
          <div className="card-section head d-flex flex-row justify-content-between align-items-center  mb-4">
            <h3>Labels Purchased</h3>
            <Dropdown className="">
              <Dropdown.Toggle id="dropdown-basic">{title}</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setDays(7);
                    handleToggle(7);
                    props.getLabelsForChart(getDaysBehind(7));
                    getGraphData(7);
                  }}
                >
                  Last 7 Days
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setDays(15);
                    handleToggle(15);
                    props.getLabelsForChart(getDaysBehind(14));
                    getGraphData(15);
                  }}
                >
                  Last 2 Weeks
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setDays(30);
                    handleToggle(30);
                    props.getLabelsForChart(getDaysBehind(30));
                    getGraphData(30);
                  }}
                >
                  Last Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setDays(90);
                    handleToggle(90);
                    props.getLabelsForChart(getDaysBehind(90));
                    getGraphData(90);
                  }}
                >
                  Last 3 Months
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={"card-section last"}>
            {props.gLoading ? (
              <Spinner animation="border" role="status" />
            ) : !props.gLoading &&
              (props.labels.length === 0 || checkEmptyGraph()) ? (
              <div className="empty">
                <p>
                  {`No Labels found for the last `}
                  <b>{`${days} days`}</b>
                </p>
              </div>
            ) : (
              <Bar
                data={{
                  labels: graphTitles,
                  datasets: [
                    {
                      label: "Number of Labels",
                      data: graphNums,
                      hoverBackgroundColor: getGraphColor(
                        cookies.is_test,
                        true,
                      ),
                      backgroundColor: getGraphColor(cookies.is_test, false),
                      borderRadius: 10,
                      barThickness: 16,
                      borderSkipped: false,
                    },
                  ],
                }}
                height={300}
                // width={100}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                      title: {
                        display: false,
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                        drawBorder: false,
                      },
                    },
                    y: {
                      grid: {
                        display: true,
                        drawBorder: false,
                      },
                      ticks: {
                        precision: 0,
                      },
                    },
                  },
                  elements: {
                    bar: {
                      radius: 10,
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="settings-card dash-labels labels">
        <div className="card-section head d-flex flex-row justify-content-between mb-3">
          <h3
            className={
              "d-flex flex-column flex-md-row align-items-center p-0 m-0"
            }
          >
            Recent Labels
          </h3>
          <Link
            to={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
            className="ship-label-button d-flex d-lg-none"
            onClick={() => {
              props.setApplicationContext({ returnURL: pathname });
            }}
          >
            Create Label
          </Link>
          <Link
            to={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
            className="ship-label-button d-none d-lg-flex"
            onClick={() => {
              props.setApplicationContext({ returnURL: pathname });
            }}
          >
            Create A Shipping Label
          </Link>
        </div>
        {props.labels.length === 0 ? (
          <div className={"card-section last"}>
            <div className="empty">
              <EmptyLabels />
              <p>
                When you send a package your shipment details and tracking will
                show up here.
              </p>
              <Link to={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}>
                <button>Create a Shipping Label</button>
              </Link>
            </div>
          </div>
        ) : (
          <LabelList
            list={getTableRecent()}
            recent
            dateRange={title}
            loading={props.loading}
            authType={props.context?.auth}
          />
        )}
      </div>
      {/*<div className='dash-card dash-stores'>*/}
      {/*    <div className='d-flex flex-row justify-content-between'>*/}
      {/*        <h3>Connected Stores</h3>*/}
      {/*        <Link to={ROUTES.HOME + ROUTES.STORES}>All Stores</Link>*/}
      {/*    </div>*/}
      {/*    <div className='d-flex flex-wrap'>*/}
      {/*        <div className='store-item d-flex flex-column'>*/}
      {/*            <div className='head d-flex flex-row justify-content-between align-items-center mb-3'>*/}
      {/*                <div className='title d-flex flex-row align-items-center'>*/}
      {/*                    <img src={shopifyLogo}/>*/}
      {/*                    <Dropdown>*/}
      {/*                        <Dropdown.Toggle as={Link}>Enable</Dropdown.Toggle>*/}
      {/*                    </Dropdown>*/}
      {/*                </div>*/}
      {/*                <FiMoreVertical size={25}/>*/}
      {/*            </div>*/}
      {/*            <div className='desc d-flex'>*/}
      {/*                <p>Lorem ipsum</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*        <div className='store-item d-flex flex-column'>*/}
      {/*            <div className='head d-flex flex-row justify-content-between align-items-center mb-3'>*/}
      {/*                <div className='title d-flex flex-row align-items-center'>*/}
      {/*                    <img src={shopifyLogo}/>*/}
      {/*                    <Dropdown>*/}
      {/*                        <Dropdown.Toggle as={Link}>Enable</Dropdown.Toggle>*/}
      {/*                    </Dropdown>*/}
      {/*                </div>*/}
      {/*                <FiMoreVertical size={25}/>*/}
      {/*            </div>*/}
      {/*            <div className='desc d-flex'>*/}
      {/*                <p>Lorem ipsum</p>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*        <div className='store-item empty d-flex flex-column justify-content-center align-items-center'>*/}
      {/*            <div className='mb-3'>*/}
      {/*                <FiPlus size={35}/>*/}
      {/*            </div>*/}
      {/*            <p>Add Other APIs</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div> */}
      {/*<div className='dash-card dash-others'>
                <h3>Others</h3>
                <div className='col-12 p-0 d-flex flex-row justify-content-between'>
                    <div className='videos'>
                        <div className='title d-flex flex-row align-items-center mb-4'>
                            <VideoIcon/>
                            <h4 className='mb-0'>Help Videos</h4>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='video-item d-flex flex-row'>
                                <div className='thumbnail'></div>
                                <div className='d-flex flex-column'>
                                    <p className='category'>Getting Started</p>
                                    <p className='title'>How to purchase a shipping Label</p>
                                    <p className='length'>2 min</p>
                                </div>
                            </div>
                            <div className='video-item d-flex flex-row'>
                                <div className='thumbnail'></div>
                                <div className='d-flex flex-column'>
                                    <p className='category'>Getting Started</p>
                                    <p className='title'>How to purchase a shipping Label</p>
                                    <p className='length'>2 min</p>
                                </div>
                            </div>

                        </div>
                        <div className='more col-12 p-0 d-flex justify-content-center align-items-center'>
                            <a>View More</a>
                        </div>
                    </div>
                    <div className='help'>
                        <div className=' title d-flex flex-row align-items-center mb-4'>
                            <HelpIcon/>
                            <h4 className='mb-0'>Help</h4>
                        </div>
                        <div className='d-flex flex-column'>
                           
                            <div className='help-item d-flex flex-row align-items-center justify-content-between'>
                                <div>
                                    <p className='category'>Getting Started</p>
                                    <p className='title'>How do you get shipping rates?</p>
                                </div>
                                <FaSortDown size={25}/>
                            </div>
                            <div className='help-item d-flex flex-row align-items-center justify-content-between'>
                                <div>
                                    <p className='category'>Getting Started</p>
                                    <p className='title'>How do you get shipping rates?</p>
                                </div>
                                <FaSortDown size={25}/>
                            </div>
                            <div className='help-item d-flex flex-row align-items-center justify-content-between'>
                                <div>
                                    <p className='category'>Getting Started</p>
                                    <p className='title'>How do you get shipping rates?</p>
                                </div>
                                <FaSortDown size={25}/>
                            </div>
                        </div>
                        <div className='more col-12 p-0 d-flex justify-content-center align-items-center'>
                            <a>View More</a>
                        </div>
                    </div>
                </div>

            </div> */}
      {calculateProfilePercentage()[0] &&
        showWelcomeTour &&
        Object.keys(props.userInfo).length > 0 &&
        (!props.userInfo?.onboarded_at ||
          props.userInfo?.onboarded_at === "") &&
        renderWelcomeTour()}
    </div>
  );
};

const mapStateToProps = ({
  labels,
  auth,
  stores,
  userDetails,
  businessDetails,
  applicationContext,
}) => ({
  labels: labels.labelsList,
  loading: labels.loading,
  gLoading: labels.gLoading,
  errors: labels.errors,
  graphLabels: labels.graphLabels,
  user: auth.user,
  orders: stores.orders,
  storesLoading: stores.loadingOrders,
  stores: stores.stores,
  userInfo: userDetails.info,
  businessDetails: businessDetails.details,
  context: applicationContext.context,
  userDetailsError: userDetails.errors,
});

export default connect(mapStateToProps, {
  getAllLabels,
  getLabelsForChart,
  selectLabel,
  clearLabelSearch,
  setApplicationContext,
  closeProfileCompletion,
  setOnboarded,
  getAccountInfo,
  getOrders,
})(Dashboard);
