import {
  DISCONNECT_STORE,
  DISCONNECT_STORE_FAILURE,
  DISCONNECT_STORE_SUCCESS,
  GET_CONNECTED_STORES,
  GET_CONNECTED_STORES_FAILURE,
  GET_CONNECTED_STORES_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  SEARCH_ORDER_TEXT_ENTERED,
  SELECT_ORDER,
  SET_ORDERS_PAGE_SIZE,
  UPDATE_STORE_NAME,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import axios from "axios";
import { paginationHandler, parseError } from "./ActionHelpers";

export const updateStoreName = (name) => {
  return {
    type: UPDATE_STORE_NAME,
    payload: name,
  };
};

export const getConnectedStores = (onSuccess) => (dispatch) => {
  dispatch({
    type: GET_CONNECTED_STORES,
  });
  instance
    .get("platforms/shops")
    .then((res) => {
      dispatch({
        type: GET_CONNECTED_STORES_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_CONNECTED_STORES_FAILURE,
        payload: parseError(err),
      });
    });
};

const CancelToken = axios.CancelToken;
let cancel;

export const getOrders =
  (onSuccess, pageSize = 10, search = "", filters = "") =>
  (dispatch) => {
    dispatch({
      type: GET_ORDERS,
    });
    let searchURL = "";
    if (search && search !== "") {
      searchURL = "&search=" + search;
    }
    let filterURL = "";
    if (filters && filters !== "") {
      filterURL = "&" + filters;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(`platforms/orders/?page_size=${pageSize}${searchURL}${filterURL}`, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((res) => {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        dispatch({
          type: GET_ORDERS_FAILURE,
          payload: parseError(err),
        });
      });
  };

export const getOrdersByPage =
  (onSuccess, pageNo, pageSize = 10, search = "", filters = "") =>
  (dispatch) => {
    dispatch({
      type: GET_ORDERS,
    });
    let searchURL = "";
    if (search && search !== "") {
      searchURL = "&search=" + search;
    }
    let filterURL = "";
    if (filters && filters !== "") {
      filterURL = "&" + filters;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(
        `platforms/orders/?page_size=${pageSize}&page=${pageNo}${searchURL}${filterURL}`,
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        },
      )
      .then((res) => {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        dispatch({
          type: GET_ORDERS_FAILURE,
          payload: parseError(err),
        });
      });
  };

export const selectOrder = (order, onSuccess) => (dispatch) => {
  dispatch({
    type: SELECT_ORDER,
    payload: order,
  });
  if (onSuccess) onSuccess();
};

export const disconnectStore = (shop_id, onSuccess) => (dispatch) => {
  dispatch({
    type: DISCONNECT_STORE,
  });

  instance
    .delete(`platforms/shops/${shop_id}`)
    .then((res) => {
      dispatch({
        type: DISCONNECT_STORE_SUCCESS,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DISCONNECT_STORE_FAILURE,
        payload: parseError(err),
      });
    });
};

export const searchOrders = (searchPhrase) => (dispatch) => {
  dispatch({
    type: SEARCH_ORDER_TEXT_ENTERED,
    payload: searchPhrase,
  });
};

export const syncOrders =
  (onSuccess, pageSize = 10, search = "") =>
  (dispatch) => {
    dispatch({
      type: GET_ORDERS,
    });

    let searchURL = "";
    if (search !== "") {
      searchURL = "&search=" + search;
    }

    instance
      .get(`platforms/orders/?sync=true&page_size=${pageSize}${searchURL}`)
      .then((res) => {
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_ORDERS_FAILURE,
          payload: parseError(err),
        });
      });
  };

export const setOrdersPageSize = (size) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_PAGE_SIZE,
    payload: size,
  });
};

export const getOrderForCreation = (orderId) => (dispatch) => {
  instance
    .get(`platforms/orders/${orderId}`)
    .then((res) => {
      dispatch(selectOrder(res.data));
    })
    .catch((err) => {
      console.log("Selecting order failed: ", parseError(err));
    });
};
