import React from "react";
import Footer from "../components/Footer";

const PrivacyPage = () => (
  <div className="">
    <section className="features-icons bg-light">
      <div className="container">
        <div className="row">
          <h1 className="display-3"> Privacy Policy</h1>
          <p>
            We at ShipShap know you care about how your personal information is
            used and shared, and we take your privacy seriously. Please read the
            following to learn more about our Privacy Policy. By using or
            accessing the Services in any manner, you acknowledge that you
            accept the practices and policies outlined in this Privacy Policy,
            and you hereby consent that we will collect, use, and share your
            information in the following ways.
          </p>
          <p>
            Remember that your use of ShipShap’s Services is at all times
            subject to the Terms of Use, which incorporates this Privacy Policy.
            Any terms we use in this Policy without defining them have the
            definitions given to them in the Terms of Use.
          </p>

          <h3> What does this Privacy Policy cover? </h3>
          <p>
            {" "}
            This Privacy Policy covers our treatment of personally identifiable
            information (“Personal Information”) that we gather when you are
            accessing or using our Services, but not to the practices of
            companies we don’t own or control, or people that we don’t manage.
          </p>
          <h3>How do we use the information? </h3>
          <p>
            {" "}
            We gather various types of Personal Information from our users, as
            explained in more detail below, and we use this Personal Information
            internally in connection with our Services, including to
            personalize, provide, and improve our services, to allow you to set
            up a user account and profile, to contact you and allow other users
            to contact you, to fulfill your requests for certain products and
            services, and to analyze how you use the Services. In certain cases,
            we may also share some Personal Information with third parties, but
            only as described below.
          </p>
          <h3> Children Privacy </h3>
          <p>
            {" "}
            As noted in the Terms of Use, we do not knowingly collect or solicit
            personal information from anyone under the age of 13. If you are
            under 13, please do not attempt to register for the Services or send
            any personal information about yourself to us. If we learn that we
            have collected personal information from a child under age 13, we
            will delete that information as quickly as possible. If you believe
            that a child under 13 may have provided us personal information,
            please contact us at privacy@shipshap.com.
          </p>
          <h3> Will ShipShap ever change this Privacy Policy? </h3>
          <p>
            We’re constantly trying to improve our Services, so we may need to
            change this Privacy Policy from time to time as well, but we will
            alert you to changes by placing a notice on the ShipShap website, by
            sending you an email, and/or by some other means. Please note that
            if you’ve opted not to receive legal notice emails from us (or you
            haven’t provided us with your email address), those legal notices
            will still govern your use of the Services, and you are still
            responsible for reading and understanding them. If you use the
            Services after any changes to the Privacy Policy have been posted,
            that means you agree to all of the changes. Use of information we
            collect now is subject to the Privacy Policy in effect at the time
            such information is collected.
          </p>

          <h3> What Information does ShipShap Collect? </h3>
          <br />
          <h4> Information You Provide to Us: </h4>
          <p>
            We receive and store any information you knowingly provide to us.
            For example, through the registration process and/or through your
            account settings, we may collect Personal Information such as your
            name, email address, phone number, and third-party account
            credentials (for example, your log-in credentials for your accounts
            with FedEx, UPS, eBay, Amazon or other third party couriers or
            marketplace integrations available through the Services. If you
            provide your third-party account credentials to us, you understand
            some content and/or information in those accounts (“Third Party
            Account Information”) may be transmitted into your account with us,
            and that Third Party Account Information, such as your billing
            address, that is transmitted to our Services is covered by this
            Privacy Policy. Certain information may be required to register with
            us or to take advantage of some of our features.
          </p>

          <p>
            We may communicate with you if you’ve provided us the means to do
            so. For example, if you’ve given us your email address, we may send
            you promotional email offers on behalf of other businesses, or email
            you about your use of the Services. Also, we may receive a
            confirmation when you open an email from us. This confirmation helps
            us make our communications with you more interesting and improve our
            services. If you do not want to receive communications from us,
            please indicate your preference by contacting us at
            privacy@shipshap.com or unsubscribing via the “Unsubscribe” button
            in our emails, if available.
          </p>

          <h4> Information Collected Automatically </h4>

          <p>
            Whenever you interact with our Services, we automatically receive
            and record information on our server logs from your browser or
            device, which may include your IP address, geolocation data, device
            identification, “cookie” information, the type of browser and/or
            device you’re using to access our Services, and the page or feature
            you requested. “Cookies” are identifiers we transfer to your browser
            or device that allow us to recognize your browser or device and tell
            us how and when pages and features in our Services are visited and
            by how many people. You may be able to change the preferences on
            your browser or device to prevent or limit your device’s acceptance
            of cookies, but this may prevent you from taking advantage of some
            of our features.
          </p>

          <p>
            If you click on a link to a third party website or service, a third
            party may also transmit cookies to you. Again, this Privacy Policy
            does not cover the use of cookies by any third parties, and we
            aren’t responsible for their privacy policies and practices. Please
            be aware that cookies placed by third parties may continue to track
            your activities online even after you have left our Services, and
            those third parties may not honor “Do Not Track” requests you have
            set using your browser or device.
          </p>

          <p>
            We may use this data to customize content for you that we think you
            might like, based on your usage patterns. We may also use it to
            improve the Services – for example, this data can tell us how often
            users use a particular feature of the Services, and we can use that
            knowledge to make the Services interesting to as many users as
            possible.
          </p>

          <h4>
            {" "}
            Information Collected From Other Websites and Do Not Track
            Policy{" "}
          </h4>
          <p>
            Through cookies we place on your browser or device, we may collect
            information about your online activity after you leave our Services.
            Just like any other usage information we collect, this information
            allows us to improve the Services and customize your online
            experience, and otherwise as described in this Privacy Policy. Your
            browser may offer you a “Do Not Track” option, which allows you to
            signal to operators of websites and web applications and services
            (including behavioral advertising services) that you do not wish
            such operators to track certain of your online activities over time
            and across different websites. Our Services do not support Do Not
            Track requests at this time, which means that we collect information
            about your online activity both while you are using the Services and
            after you leave our Services.
          </p>

          <h3>
            {" "}
            Will ShipShap Share Any of the Personal Information it Receives?
          </h3>
          <p>
            We do not rent or sell your Personal Information in personally
            identifiable form to anyone, provided certain Personal Information
            may be transferred in connection with business transfers, as
            described below. We may share your Personal Information with third
            parties as described in this section:
          </p>
          <p>
            Information that’s been de-identified. We may de-identify your
            Personal Information so that you are not identified as an
            individual, and provide that information to our partners. We may
            also provide aggregate usage information to our partners (or allow
            partners to collect that information from you), who may use such
            information to understand how often and in what ways people use our
            Services, so that they, too, can provide you with an optimal online
            experience. However, we never disclose aggregate usage or
            de-identified information to a partner (or allow a partner to
            collect such information) in a manner that would identify you as an
            individual person.
          </p>
          <p>
            Advertisers: We allow advertisers and/or merchant partners
            (“Advertisers”) to choose the demographic information of users who
            will see their advertisements and/or promotional offers and you
            agree that we may provide any of the information we have collected
            from you in non-personally identifiable form to an Advertiser, in
            order for that Advertiser to select the appropriate audience for
            those advertisements and/or offers. For example, we might use the
            fact you are located in San Francisco to show you ads or offers for
            San Francisco businesses, but we will not tell such businesses who
            you are. Or, we might allow Advertisers to display their ads to
            users with similar usage patterns to yours, but we will not disclose
            usage information to Advertisers except in aggregate form, and not
            in a manner that would identify you personally. Note that if an
            advertiser asks us to show an ad to a certain audience or audience
            segment and you respond to that ad, the advertiser may conclude that
            you fit the description of the audience they were trying to reach.
          </p>
          <p>
            Affiliated Businesses: In certain situations, businesses or third
            party websites we’re affiliated with may sell or provide products or
            services to you through or in connection with the Services (either
            alone or jointly with us). You can recognize when an affiliated
            business is associated with such a transaction or service, and we
            will share your Personal Information with that affiliated business
            only to the extent that it is related to such transaction or
            service. One such service may include the ability for you to
            automatically transmit certain information regarding your orders to
            a Courier to help them provide the delivery services you order. As
            another example, we may provide integrations with certain
            marketplaces or selling platforms (e.g. eBay and Amazon) and we may
            share certain information regarding your transactions conducted via
            such integrations with the applicable marketplace or platform. We
            have no control over the policies and practices of third party
            websites or businesses as to privacy or anything else, so if you
            choose to take part in any transaction or service relating to an
            affiliated website or business, please review all such business’ or
            websites’ policies.
          </p>
          <p>
            Agents: We employ other companies and people to perform tasks on our
            behalf and need to share your information with them to provide
            products or services to you; for example, we may use a payment
            processing company to receive and process your credit card
            transactions for us. Unless we tell you differently, our agents do
            not have any right to use the Personal Information we share with
            them beyond what is necessary to assist us.
          </p>
          <p>
            Business Transfers: We may choose to buy or sell assets, and may
            share and/or transfer customer information in connection with the
            evaluation of and entry into such transactions.Also, if we (or our
            assets) are acquired, or if we go out of business, enter bankruptcy,
            or go through some other change of control, Personal Information
            could be one of the assets transferred to or acquired by a third
            party.
          </p>
          <p>
            Protection of ShipShap and Others: We reserve the right to access,
            read, preserve, and disclose any information that we believe is
            necessary to comply with law or court order; enforce or apply our
            Terms of Use and other agreements; or protect the rights, property,
            or safety of ShipShap, our employees, our users, or others.
          </p>

          <h3>Is Personal Information about me secure? </h3>
          <p>
            We use all appropriate technical, organizational and administrative
            security measures to protect any information we hold in our records
            from loss, misuse, and unauthorized access, disclosure, alteration
            and destruction. Unfortunately, no company or service can guarantee
            complete security. Unauthorized entry or use, hardware or software
            failure, and other factors, may compromise the security of user
            information at any time.
          </p>
          <p>
            Among other practices, your account is protected by a password for
            your privacy and security. If you access your account via a third
            party site or service, you may have additional or different sign-on
            protections via that third party site or service. You must prevent
            unauthorized access to your account and Personal Information by
            selecting and protecting your password and/or other sign-on
            mechanism appropriately and limiting access to your computer or
            device and browser by signing off after you have finished accessing
            your account.
          </p>
          <h3> What Personal Information can I access? </h3>
          <p>
            Through your account settings, you may access, and, in some cases,
            edit or delete the following information you’ve provided to us:
          </p>
          <p>
            name and password email address location user profile information,
            including billing and shipping addresses, customer addresses, and
            payment methods Third-party account credentials The information you
            can view, update, and delete may change as the Services change. If
            you have any questions about viewing or updating information we have
            on file about you, please contact us at privacy@shipshap.com.
          </p>
          <p>
            Under California Civil Code Sections 1798.83-1798.84, California
            residents are entitled to contact us to prevent disclosure of
            Personal Information to third parties for such third parties’ direct
            marketing purposes; in order to submit such a request, please
            contact us at privacy@shipshap.com.
          </p>

          <h4>What choices do I have? (Data Subject Rights)</h4>
          <p>
            You can always opt not to disclose information to us, but keep in
            mind some information may be needed to register with us or to take
            advantage of some of our features.
          </p>
          <p>
            Cookies: You can accept or reject cookies by adjusting your web
            browser controls. Please consult our Cookies Policy for more
            information about our use of cookies and how to accept and reject
            them.
          </p>
          <p>
            Marketing Communications: You can opt-out of receiving certain
            promotional or marketing communications from us at any time, by
            using the unsubscribe link in the emails communications we send.
          </p>
          <h4>
            How can I update and access my information (Exercise my Data Subject
            Rights)?{" "}
          </h4>
          <p>
            If you would like to access, review, update, rectify, or delete any
            Personal Information we hold about you, or exercise any other data
            subject right available to you under the EU General Data Protection
            Regulation (GDPR), or under the EU-US Privacy Shield Framework where
            applicable (see below), you can fill out this web form directly.
          </p>
          <p>
            Our Privacy team will examine your request and respond to you as
            quickly as possible! Some information may remain in our records
            after the deactivation of your account. Please note that we may use
            any aggregated data derived from or incorporating your Personal
            Information after you update or delete it, but not in a manner that
            would identify you personally.
          </p>

          <h3>What if I have questions about this policy?</h3>
          <p>
            If you have any questions or concerns regarding our privacy
            policies, please send us a detailed message to privacy@shipshap.com,
            and we will try to resolve your concerns.
          </p>
        </div>
      </div>
    </section>
  </div>
);
export default PrivacyPage;
