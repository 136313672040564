import "../scss/kq-landing.scss";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import React, { useRef, useState } from "react";
import { ReactComponent as BoxLogo } from "../assets/box-in-logo.svg";
import DESINGED_FOR from "../assets/desinged-for.png";
import GETTING_STARTED from "../assets/getting-started.png";
import { ReactComponent as SaveFeature } from "../assets/save-feature-icon.svg";
import OnePlatform from "../assets/one-platform-feature-icon.png";
import { ReactComponent as SeamlessIntegration } from "../assets/seamless-integration-feature-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import { FiMinus, FiPlus } from "react-icons/fi";
import SIMPLIFY_SHIPPING from "../assets/simplify-shipping-dashboard.png";
import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";
import { PopupButton, Widget } from "@typeform/embed-react";

const FAQs = [
  {
    title: "What is ShipShap Economy?",
    description_text: (
      <p>
        ShipShap Economy is a cost-effective and efficient shipping solution
        designed specifically for businesses and individuals targeting the US
        market. <br />
        <br />
        By consolidating parcels and ensuring shipment within 7–10 days, this
        tailor-made service significantly reduces shipping costs without
        compromising on reliability. In an industry where high shipping rates
        and limited options are common challenges, ShipShap Economy provides a
        streamlined and affordable alternative <br />
        <br />
        Whether you’re a merchant looking to expand your reach or an individual
        shipping to the US, ShipShap Economy is here to make global logistics
        more accessible and budget-friendly.
      </p>
    ),
  },
  {
    title: "What is the estimated time of delivery?",
    description_text: (
      <p>
        The estimated delivery time for most orders is between 7 to 10 business
        days. However, please note that this time frame may vary depending on
        the destination, shipping method selected, and any unforeseen
        circumstances such as customs delays or weather disruptions. We
        recommend checking the tracking details provided once your order has
        shipped for the most accurate delivery estimate.
      </p>
    ),
  },
  {
    title: "Do we deliver to the door?",
    description_text: (
      <p>
        Yes, we offer door-to-door delivery for all orders. This means that once
        your package is shipped, it will be delivered directly to your specified
        address. Please ensure that someone is available to receive the package
        at the time of delivery, or that you provide any necessary instructions
        for delivery if you are not available.
      </p>
    ),
  },
  {
    title: "Are there any prohibited items?",
    description_text: (
      <p>
        Yes, there are certain items that we are unable to ship due to legal and
        regulatory restrictions. Prohibited items include, but are not limited
        to, illegal substances, narcotics, drugs, alcohol, tobacco products, and
        hazardous materials. Additionally, certain items may be restricted
        depending on the destination country’s regulations. We advise reviewing
        your order to ensure that it complies with shipping guidelines, and if
        you have any questions regarding specific items, please feel free to
        contact us.
      </p>
    ),
  },
  {
    title: "Do you ship to other countries?",
    description_text: (
      <p>
        Yes, we ship to almost all countries around the world. Our global
        shipping network allows us to deliver to a wide range of international
        destinations. While we strive to serve customers globally, there may be
        some regions or countries where shipping is unavailable due to
        logistical or regulatory challenges. If you are located in an area where
        shipping is limited, we will notify you accordingly. For international
        orders, please be aware that customs fees or import duties may apply,
        depending on the destination country’s import policies.
      </p>
    ),
  },
];

const KQLanding = () => {
  const [shouldPlay, setShouldPlay] = useState(false);

  return (
    <div className={"kq-landing"}>
      <section className={"masthead"}>
        <div
          className={
            "content d-flex flex-column justify-content-center align-items-start"
          }
        >
          <h2>SIMPLIFY SHIPPING.</h2>
          <h1>AMPLIFY SALES.</h1>
          <p>
            Save up to 40% on shipping costs and streamline your logistics
            today!
          </p>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-start align-items-lg-center "
            }
          >
            <PopupButton id={"kFi9x3ny"} size={60}>
              Sign Up Now - It's Free!
            </PopupButton>
            <a href={"#economy"}>
              <button>Learn More About ShipShap Economy</button>
            </a>
          </div>
        </div>
      </section>
      <section className={"economy-video"} id={"economy"}>
        <h1>What is ShipShap Economy?</h1>
        <p>
          ShipShap Economy is an all-in-one shipping solution that helps Kenyan
          merchants streamline logistics. Manage your shipments, track orders,
          and save money—all from a single platform.
        </p>
        <Waypoint
          scrollableAncestor={document.getElementsByClassName("kq-landing")[0]}
          bottomOffset={"20%"}
          onEnter={() => {
            setShouldPlay(true);
          }}
          onLeave={() => {
            setShouldPlay(false);
          }}
        />
        <ReactPlayer
          url={
            "https://storage.googleapis.com/shipshap-prod-static/assets/SHIPSHAP%20EXPLAINER%20VIDEO-2.mp4"
          }
          controls
          playing={shouldPlay}
          width={"80%"}
          height={"100%"}
          muted
        />
      </section>
      <section
        className={
          "designed-for d-flex flex-column justify-content-center align-items-center"
        }
      >
        <div
          className={
            "d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center mb-5"
          }
        >
          <div className={"content"}>
            <h2>Designed for Kenyan Businesses.</h2>
            <p>ShipShap Economy is designed for:</p>
            <ul>
              <li>E-commerce merchants exporting globally.</li>
              <li>SMEs shipping locally within Kenya.</li>
              <li>Entrepreneurs managing multiple couriers and platforms.</li>
            </ul>
          </div>
          <div className={"image col-lg-6 p-0"}>
            <img src={DESINGED_FOR} alt={"lady using shipshap dashboard"} />
          </div>
        </div>
        <PopupButton id={"kFi9x3ny"} size={60}>
          Find Out If ShipShap is Right for You
        </PopupButton>
      </section>
      <section
        className={
          "get-started d-flex flex-column justify-content-center justify-content-lg-between align-items-center"
        }
      >
        <div
          className={
            "d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center mb-5 w-100"
          }
        >
          <div className={"image col-lg-5 p-0"}>
            <img src={GETTING_STARTED} alt={"shipshap dashboard preview"} />
          </div>
          <div className={"content col-lg-6 p-0"}>
            <h2>Getting Started is Simple</h2>
            <p>
              <b>Step 1: </b> Sign up with your email address.
            </p>
            <p>
              <b>Step 2: </b> Upload proof of business certification.
            </p>
            <p>
              <b>Step 3: </b> Connect your store or platform.
            </p>
            <p>
              <b>Step 4: </b> Start shipping and saving.
            </p>
          </div>
        </div>
        <PopupButton id={"kFi9x3ny"} size={60}>
          Sign Up - It's Free!
        </PopupButton>
      </section>
      <section
        className={
          "everything-you-need d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>Everything You Need for Hassle-Free Shipping</h2>
        <div className={"feature-cards"}>
          <div>
            <div>
              <SaveFeature />
            </div>
            <h3>Save up to 40%</h3>
            <p>Enjoy up to 40% savings on shipping.</p>
          </div>
          <div className={"active"}>
            <div>
              <img
                src={OnePlatform}
                alt={"one platfrom for all shipping needs"}
              />
            </div>
            <h3>One platform for all your shipping needs</h3>
            <p>
              Manage orders, create shipping labels, and track shipments in
              real-time.
            </p>
          </div>
          <div>
            <div>
              <SeamlessIntegration />
            </div>
            <h3>Seamless Integration</h3>
            <p>Connect with platforms like Shopify, Etsy, and WooCommerce.</p>
          </div>
        </div>
        <PopupButton id={"kFi9x3ny"} size={60}>
          Sign Up Now - It's Free!
        </PopupButton>
      </section>
      <section
        className={
          "faq d-flex flex-column justify-content-center align-items-center"
        }
      >
        <h2>Have Questions?</h2>
        <p>We're here to answer all your questions</p>
        <Accordion>
          {FAQs.map((item, idx) => {
            return (
              <Accordion.Item eventKey={idx} key={idx}>
                <Accordion.Header className={"w-100 m-0"}>
                  {`${item.title}`}
                  <FiPlus className={"plus"} />
                  <FiMinus className={"minus"} />
                </Accordion.Header>
                <Accordion.Body>{item.description_text}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>
      <section
        className={
          "event-details d-flex flex-column flex-lg-row align-items-lg-center"
        }
        id={"shipsmart-summit"}
      >
        <div
          className={
            "content col-lg-6 p-0 d-flex flex-column justify-content-center align-items-start"
          }
        >
          <h2>How to Ship Globally Hassle-Free</h2>
          <p>
            Join us at <b>ShipSmart Summit 2025</b>, where we’ll uncover
            actionable strategies to help Kenyan merchants scale their
            e-commerce businesses globally.
          </p>
          <p>
            Learn how to navigate the online buyer journey, optimize logistics,
            and leverage ShipShap’s tools to simplify shipping and increase
            customer satisfaction.
          </p>
        </div>
        <div
          className={
            "event-cta col-lg-6 d-flex justify-content-center align-items-center"
          }
        >
          <div className={"cta-card"}>
            <h3>Event details</h3>
            <p>March 5th, 10am - 11am</p>
            <p>Limited Spaces</p>
            <a
              href={
                "https://www.eventbrite.com/e/how-to-ship-globally-hassle-free-tickets-1245788961319?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl"
              }
              target={"_blank"}
            >
              <button>Register Now</button>
            </a>
          </div>
        </div>
      </section>
      <section
        className={
          "simplify-shipping d-flex flex-column justify-content-center align-items-center"
        }
      >
        <div
          className={
            "d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center mb-5"
          }
        >
          <div className={"content"}>
            <h2>Start Simplifying Your Shipping Today.</h2>
            <p>Take Your Business Further with ShipShap Economy</p>
          </div>
          <div className={"image col-lg-6 p-0"}>
            <img
              src={SIMPLIFY_SHIPPING}
              alt={"lady using shipshap on laptop to make label"}
            />
          </div>
        </div>
        <PopupButton id={"kFi9x3ny"} size={60}>
          Sign Up Now - It's Free!
        </PopupButton>
      </section>
      <section
        className={
          "footer d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center "
        }
      >
        <div
          className={
            "d-flex flex-row justify-content-center align-items-center justify-content-lg-start align-items-lg-start"
          }
        >
          <Navbar.Brand
            className="p-0 m-0 d-flex align-items-center align-items-lg-start"
            href="#home"
          >
            <Link
              className="logo d-flex flex-row align-items-center"
              to={ROUTES.LANDING}
            >
              <div>
                <BoxLogo />
              </div>
              <h2 className="mb-0">ShipShap</h2>
            </Link>
          </Navbar.Brand>
        </div>
        <div>
          <p>
            2025 © ShipShap Ltd. All rights reserved. ShipShap is a registered
            trademarks of ShipShap Ltd.
          </p>
          <p>
            Terms and conditions, features, support, pricing and service options
            subject to change without notice.
          </p>
          <div
            className={
              "links d-flex flex-column flex-md-row align-items-center justify-content-center"
            }
          >
            <a
              href={"https://www.tiktok.com/@shipshapglobal"}
              target={"_blank"}
            >
              TikTok
            </a>
            <div></div>
            <a
              href={"https://www.instagram.com/shipshapglobal"}
              target={"_blank"}
            >
              Instagram
            </a>
            <div></div>
            <Link to={ROUTES.TERMS}>Terms of Service</Link>
            <div></div>
            <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
            <div></div>
            <Link to={ROUTES.CONTACT_US}>Contact Us</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KQLanding;
