import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {
  FiLogOut,
  FiSearch,
  FiSettings,
  FiX,
  FiShoppingCart,
  FiPackage,
} from "react-icons/fi";
import {
  selectLabel,
  clearLabelSearch,
  searchLabels,
} from "../actions/LabelActions";
import { IoMdNotifications } from "react-icons/io";
import Popup from "./common/Popup";
import {
  logoutSession,
  logOutUser,
  searchAddresses,
  searchOrders,
  searchTransactions,
} from "../actions";
import { useCookies } from "react-cookie";
import { FIREBASE_AUTH, SESSION_AUTH } from "../../constants/types";
import { NavItem } from "./SideBar";
import { ReactComponent as OverviewIcon } from "../../assets/overview-icon.svg";
import { ReactComponent as LabelsIcon } from "../../assets/labels-icon.svg";
import { ReactComponent as OrdersIcon } from "../../assets/orders-icon.svg";
import { ReactComponent as BoxLogo } from "../../assets/box-in-logo.svg";
import { LuWallet } from "react-icons/lu";
import {
  getRedirectURL,
  isAllowedRedirect,
  isWalletAccount,
} from "./common/helpers";
import { getWebToken } from "../actions/ActionHelpers";

const Header = (props) => {
  const [showPopup, setPopup] = useState(false);
  const [popElement, setPopupElement] = useState(null);
  const [webToken, setWebToken] = useState(null);

  const history = useHistory();
  const { pathname } = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();

  const showWallet = isWalletAccount(
    props.context?.auth,
    props.userInfo?.email,
  );

  const redirectURL = (route) =>
    getRedirectURL(route, webToken, props.context?.auth);

  useEffect(() => {
    const awaitWebToken = async () => {
      const token = await getWebToken();
      setWebToken(token);
    };
    awaitWebToken();
  }, []);

  const inital = (name) => {
    if (!name) {
      return "?";
    }
    const first_init = name.substring(0, 1);

    if (name.indexOf(" ") === -1) {
      var second_init = name.substring(1, 2);
    } else {
      var second_init = name.substring(
        name.indexOf(" ") + 1,
        name.indexOf(" ") + 2,
      );
    }

    return first_init.toUpperCase() + second_init.toUpperCase();
  };

  const togglePopup = (element) => {
    setPopupElement(element);
    setPopup(!showPopup);
  };

  const handleSearch = (searchPhrase) => {
    switch (pathname) {
      case ROUTES.HOME + ROUTES.LABEL:
        props.searchOrders("");
        props.searchAddresses("");
        props.searchTransactions("");
        props.searchLabels(searchPhrase);
        break;
      case ROUTES.HOME + ROUTES.ORDERS:
        props.searchLabels("");
        props.searchAddresses("");
        props.searchTransactions("");
        props.searchOrders(searchPhrase);
        break;
      case ROUTES.HOME + ROUTES.WALLETS:
        props.searchLabels("");
        props.searchOrders("");
        props.searchAddresses("");
        props.searchTransactions(searchPhrase);
        break;
      case ROUTES.ADDRESS_LIST:
        props.searchLabels("");
        props.searchOrders("");
        props.searchTransactions("");
        props.searchAddresses(searchPhrase);
        break;
      default:
        break;
    }
  };

  const getSearchText = () => {
    switch (pathname) {
      case ROUTES.HOME + ROUTES.LABEL:
        return props.labelSearch;
      case ROUTES.HOME + ROUTES.ORDERS:
        return props.orderSearch;
      case ROUTES.HOME + ROUTES.WALLETS:
        return props.transactionSearch;
      case ROUTES.ADDRESS_LIST:
        return props.addressSearch;
      default:
        return "";
    }
  };

  return (
    <div className="home-header col-12 p-0 d-flex flex-row justify-content-between align-items-center">
      <Switch>
        <Route
          path={ROUTES.HOME + ROUTES.DASHBOARD}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Dashboard</h2>
                <p>All shipping information</p>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Create Labels</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.LABEL}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Labels</h2>
              </div>
            );
          }}
        />

        <Route
          path={[ROUTES.HOME + ROUTES.ORDERS, ROUTES.CSV_IMPORT_PAGE]}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Orders</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.ACCOUNT}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Settings</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HELP_CENTER}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Help Center</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.STORES}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Stores</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.MANIFESTS}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Manifests</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.MANIFESTS + ROUTES.CREATE}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Create Manifests</h2>
              </div>
            );
          }}
        />
        <Route
          path={ROUTES.HOME + ROUTES.WALLETS}
          render={() => {
            return (
              <div className="header-info d-flex flex-column">
                <h2>Wallets</h2>
              </div>
            );
          }}
        />
      </Switch>
      <div className="header-actions col-7 col-xl-6 p-0 d-flex flex-row align-items-center justify-content-end">
        <Route
          path={[
            ROUTES.HOME + ROUTES.LABEL,
            ROUTES.HOME + ROUTES.ORDERS,
            ROUTES.HOME + ROUTES.WALLETS,
            ROUTES.ADDRESS_LIST,
          ]}
          exact
          render={() => {
            return (
              <div className="search-action">
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e.target.value)}
                  value={getSearchText()}
                />
                <FiSearch size={25} className="search-icon" />
                <FiX
                  size={25}
                  className={`clear-icon ${getSearchText().trim() !== "" ? "active" : ""}`}
                  onClick={() => handleSearch("")}
                />
              </div>
            );
          }}
        />
        {/*<div className="notif-aciton">*/}
        {/*    <IoMdNotifications size={35} />*/}
        {/*    <div className={`active-notif `}></div>*/}
        {/*</div>*/}
        <div className="account-action" onClick={(e) => togglePopup(e.target)}>
          {
            // true?
            // <img src={test} alt='profile'/>
            // :
            inital(
              props.userInfo?.full_name ||
                props.userInfo?.email ||
                props.user?.email ||
                "?",
            )
          }
        </div>
      </div>
      <Popup
        className="account-popup"
        show={showPopup}
        element={popElement}
        close={() => setPopup(false)}
      >
        <Popup.Item
          className={"d-flex d-xl-none"}
          onClick={() => {
            history.push(ROUTES.LANDING);
            setPopup(false);
          }}
        >
          <div
            className={
              "header d-flex flex-row align-items-center justify-content-start"
            }
          >
            <div>
              <BoxLogo />
            </div>
            <h2 className="mb-0">ShipShap</h2>
          </div>
        </Popup.Item>
        <Popup.Item
          className={"d-flex d-xl-none"}
          onClick={() => {
            history.push(ROUTES.HOME + ROUTES.DASHBOARD);
            setPopup(false);
          }}
        >
          <OverviewIcon />
          <p>Overview</p>
        </Popup.Item>
        <Popup.Item
          className={"d-flex d-xl-none"}
          onClick={() => {
            if (isAllowedRedirect(ROUTES.ORDERS)) {
              window.location.href = redirectURL(ROUTES.ORDERS);
            } else {
              history.push(ROUTES.HOME + ROUTES.ORDERS);
            }
            setPopup(false);
          }}
        >
          <OrdersIcon />
          <p>Orders</p>
        </Popup.Item>
        <Popup.Item
          className={"d-flex d-xl-none"}
          onClick={() => {
            if (isAllowedRedirect(ROUTES.LABEL)) {
              window.location.href = redirectURL(ROUTES.LABEL);
            } else {
              history.push(ROUTES.HOME + ROUTES.LABEL);
            }
            setPopup(false);
          }}
        >
          <LabelsIcon />
          <p>Labels</p>
        </Popup.Item>
        <Popup.Item
          className={"d-flex d-xl-none"}
          onClick={() => {
            if (isAllowedRedirect(ROUTES.MANIFESTS)) {
              window.location.href = redirectURL(ROUTES.MANIFESTS);
            } else {
              history.push(ROUTES.HOME + ROUTES.MANIFESTS);
            }
            setPopup(false);
          }}
        >
          <FiPackage />
          <p>Manifests</p>
        </Popup.Item>
        <Popup.Item
          className={"d-flex d-xl-none"}
          onClick={() => {
            history.push(ROUTES.HOME + ROUTES.STORES);
            setPopup(false);
          }}
        >
          <FiShoppingCart />
          <p>Stores</p>
        </Popup.Item>
        {showWallet && (
          <Popup.Item
            className={"d-flex d-xl-none"}
            onClick={() => {
              if (isAllowedRedirect(ROUTES.WALLETS)) {
                window.location.href = redirectURL(ROUTES.WALLETS);
              } else {
                history.push(ROUTES.HOME + ROUTES.WALLETS);
              }
              setPopup(false);
            }}
          >
            <LuWallet />
            <p>Wallets</p>
          </Popup.Item>
        )}
        <Popup.Item
          onClick={() => {
            history.push(ROUTES.HOME + ROUTES.ACCOUNT);
            setPopup(false);
          }}
        >
          <FiSettings />
          <p>Settings</p>
        </Popup.Item>
        <Popup.Item
          className="logout-action"
          onClick={() => {
            if (props.context.auth === FIREBASE_AUTH) props.logOutUser();
            else props.logoutSession();
            removeCookie("is_test", {
              domain:
                document.location.hostname.split(".").slice(-2).join(".") ||
                document.location.hostname,
              path: "/",
            });

            setPopup(false);
          }}
        >
          <FiLogOut />
          <p>Logout</p>
        </Popup.Item>
      </Popup>
    </div>
  );
};

const mapStateToProps = ({
  auth,
  labels,
  stores,
  userDetails,
  addressList,
  applicationContext,
  wallets,
}) => ({
  user: auth.user,
  labelSearch: labels.searchText,
  orderSearch: stores.searchText,
  addressSearch: addressList.searchText,
  transactionSearch: wallets.searchText,
  userInfo: userDetails.info,
  context: applicationContext.context,
});

export default connect(mapStateToProps, {
  searchLabels,
  logOutUser,
  clearLabelSearch,
  searchOrders,
  searchAddresses,
  searchTransactions,
  logoutSession,
})(Header);
