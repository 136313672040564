import { functions, analytics } from "../../services/firebase";
import {
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
  CARD_LIST,
  CARD_PAY,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE,
  DELETE_CARD_FAILURE,
  SET_DEFAULT_CARD,
  UPDATE_ACCOUNT_FORM_FIELD,
  SAVE_ACCOUNT_INFO,
  SAVE_ACCOUNT_INFO_FAILURE,
  SAVE_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_FAILURE,
  STRIPE,
  PAYSTACK,
  SET_DEFAULT_CARD_SUCCESS,
  SET_DEFAULT_CARD_FAILED,
  SAFARICOM,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";

export const getUserDetails = (onSuccess) => (dispatch) => {
  dispatch({ type: GET_USER_DETAILS });
  const httpsCallable = functions.httpsCallable("user_details");
  let responseData = {};
  httpsCallable().then((response) => {
    responseData = {
      ...response,
      data: {
        ...response.data,
      },
    };
  });
  instance
    .get("/payments/payment-methods")
    .then((res) => {
      getUserDetailsSuccess(
        dispatch,
        {
          ...responseData,
          data: {
            ...responseData.data,
            success: true,
            credit_cards: res.data,
          },
        },
        onSuccess,
      );
    })
    .catch((err) => {
      const errMsg = parseError(err);
      console.log(err);
      dispatch({
        type: GET_USER_DETAILS_FAILURE,
        payload: errMsg,
      });
    });
};

const getUserDetailsSuccess = (dispatch, data, onSuccess) => {
  if (data.data.success) {
    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: data.data,
    });
    if (onSuccess) {
      onSuccess();
    }
  } else {
    const errors =
      "errors" in data.data
        ? data.errors
        : "Oops there was a problem - try again shortly";
    dispatch({
      type: GET_USER_DETAILS_FAILURE,
      payload: errors,
    });
  }
};

const getUserDetailsFailed = (dispatch, error) => {
  dispatch({
    type: GET_USER_DETAILS_FAILURE,
    payload: { errors: "Oops there was a problem - try again shortly" },
  });
  console.error(`this is the error for user_details : ${error}`);
};

export const addUserCard = (data, onSuccess) => (dispatch) => {
  dispatch({
    type: ADD_CARD,
  });
  switch (data.provider) {
    case STRIPE:
      instance
        .post("/payments/payment-method-setup", {
          provider: STRIPE,
        })
        .then(async (response) => {
          await data.stripe
            .confirmCardSetup(response.data.client_secret, {
              payment_method: {
                card: data.element,
                billing_details: data.billingDetails,
              },
            })
            .then(async (res) => {
              if (Object.keys(res).includes("error")) {
                console.log("Got error while confirming setup intent: ");
                console.log(res.error);
                dispatch({
                  type: ADD_CARD_FAILURE,
                  payload: res.error.message,
                });
                return;
              }

              instance
                .post("/payments/payment-methods", {
                  ...data.data,
                  provider: data.provider,
                  id_in_provider: res.setupIntent.payment_method,
                })
                .then((res) => {
                  dispatch({
                    type: ADD_CARD_SUCCESS,
                  });

                  if (onSuccess) onSuccess(res.data.id);
                })
                .catch((err) => {
                  console.log(err);
                  const errMsg = parseError(err);
                  dispatch({
                    type: ADD_CARD_FAILURE,
                    payload: errMsg,
                  });
                });
            })
            .catch((error) => {
              console.log("Got error while confirming setup intent: ");
              console.log(error);
              dispatch({
                type: ADD_CARD_FAILURE,
                payload: parseError(error),
              });
            });
        })
        .catch((err) => {
          console.log("error while getting setup intent client secret: ");
          console.log(err);

          dispatch({
            type: ADD_CARD_FAILURE,
            payload: parseError(err),
          });
        });
      break;
    case PAYSTACK:
    case SAFARICOM:
      instance
        .post("/payments/payment-methods", data)
        .then((res) => {
          dispatch({
            type: ADD_CARD_SUCCESS,
            payload: res.data,
          });

          if (onSuccess) onSuccess(res.data.id);
        })
        .catch((err) => {
          console.log(err);
          const errMsg = parseError(err);
          dispatch({
            type: ADD_CARD_FAILURE,
            payload: errMsg,
          });
        });
      break;
    default:
      dispatch({
        type: ADD_CARD_FAILURE,
        payload: "Payment provider not specified",
      });
      break;
  }
};

export const removeUserCard = (card_id, onSuccess) => (dispatch) => {
  dispatch({ type: DELETE_CARD });

  instance
    .delete(`/payments/payment-methods/${card_id}`)
    .then((response) => {
      //TODO: DISPATCH A SUCCESSFUL THING HERE AND REMOVE FROM LIST
      removeCardSuccess(dispatch, card_id);
      onSuccess();
    })
    .catch((httpsError) => {
      dispatch({
        type: DELETE_CARD_FAILURE,
        payload: "Oops there was a problem - try again shortly",
      });
      console.log("Error while removing user card: ", httpsError);
    });
};

const removeCardSuccess = (dispatch, card) => {
  dispatch({ type: DELETE_CARD_SUCCESS, payload: card });
};

export const setDefaultCard = (card, onSuccess) => (dispatch) => {
  dispatch({ type: SET_DEFAULT_CARD, payload: card });

  instance
    .patch(`payments/payment-methods/${card.id}`, {
      is_default: true,
    })
    .then((res) => {
      dispatch({
        type: SET_DEFAULT_CARD_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: SET_DEFAULT_CARD_FAILED,
        payload: errMsg,
      });
    });
};

export const selectCard = (card, type, onFinish) => (dispatch) => {
  dispatch({ type: type, payload: card });

  if (type === CARD_PAY) {
    dispatch(setDefaultCard(card));
  }

  if (onFinish) onFinish();
};

export const updateAccountForm = (data) => {
  return {
    type: UPDATE_ACCOUNT_FORM_FIELD,
    payload: data,
  };
};

export const saveAccountInfo = (form, onSuccess) => (dispatch) => {
  dispatch({
    type: SAVE_ACCOUNT_INFO,
  });

  const full_name = form.full_name.trim();

  const data = {
    first_name: full_name.trim().substring(0, full_name.indexOf(" ")).trim(),
    last_name: full_name
      .trim()
      .substring(full_name.indexOf(" ") + 1)
      .trim(),
    phone_number: "+" + form.phone_number,
    business_name: form.business_name,
    business_website: form.business_website,
    monthly_avg_shipment: form.monthly_avg_shipment,
  };

  instance
    .patch("user_info/", data)
    .then((res) => {
      dispatch({
        type: SAVE_ACCOUNT_INFO_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status === 400) {
        dispatch({
          type: SAVE_ACCOUNT_INFO_FAILURE,
          payload: err.response.data,
        });
      } else {
        const errMsg = err.response?.data
          ? parseError(err.response.data)
          : parseError(err);
        dispatch({
          type: SAVE_ACCOUNT_INFO_FAILURE,
          payload: errMsg,
        });
      }
    });
};

export const getAccountInfo = (onSuccess, onFinish) => (dispatch) => {
  dispatch({
    type: GET_ACCOUNT_INFO,
  });

  instance
    .get("user_info/")
    .then(async (res) => {
      dispatch({
        type: GET_ACCOUNT_INFO_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) await onSuccess();
      if (onFinish) onFinish();
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: GET_ACCOUNT_INFO_FAILURE,
        payload: errMsg,
      });
      if (onFinish) onFinish();
    });
};

export const setOnboarded = (onSuccess) => (dispatch) => {
  instance
    .post("set_onboarded/")
    .then((res) => {
      dispatch({
        type: SAVE_ACCOUNT_INFO_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: SAVE_ACCOUNT_INFO_FAILURE,
        payload: errMsg,
      });
    });
};
