import React, { Component, createRef, useState } from "react";
import masthead from "../assets/masthead.png";
import ETHIO from "../assets/ethioair-icon.svg";
import DHL from "../assets/dhl-icon.svg";
import USPS from "../assets/usps-icon.svg";
import UPS from "../assets/ups-icon.svg";
import { ReactComponent as Star } from "../assets/reviewStars.svg";
import arrow from "../assets/upArrow.svg";
import next from "../assets/nextInactive.svg";
import prev from "../assets/prevInactive.svg";
import nextAct from "../assets/nextActive.svg";
import prevAct from "../assets/prevActive.svg";
import appStore from "../assets/appstore-badge.png";
import playStore from "../assets/google-play-badge.png";
import { DownloadAppLink } from "./DownloadAppButton";
import Marquee from "react-fast-marquee";
import integrations from "../assets/integrations-visual-1.svg";
import what_you_get from "../assets/what-you-get.png";
import what_you_get_mob from "../assets/what-you-get-mob.png";
import { ReactComponent as Service1 } from "../assets/service-icon-1.svg";
import { ReactComponent as Service2 } from "../assets/service-icon-2.svg";
import { ReactComponent as Service3 } from "../assets/service-icon-3.svg";
import { ReactComponent as Service4 } from "../assets/service-icon-4.svg";
import signup_mobile_0 from "../assets/signup-mobile-0.png";
import signup_mobile_1 from "../assets/signup-mobile-1.png";
import signup_mobile_2 from "../assets/signup-mobile-2.png";
import signup_mobile_3 from "../assets/signup-mobile-3.png";
import signup_mobile_4 from "../assets/signup-mobile-4.png";
import {
  FiArrowLeft,
  FiArrowRight,
  FiCheck,
  FiHome,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import shipshap_visual from "../assets/shipshap-visual.svg";
import { ReactComponent as BGDOTS } from "../assets/bg-dots.svg";
import { ReactComponent as WorldMap } from "../assets/world-map.svg";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import apis from "../assets/api-integrations.svg";

const testimonials = [
  {
    rating: 5,
    title: "Super Easy 🤩 Super Fast",
    name: "Andrew",
    comment:
      "Amazing little app! Right from my phone to my printer. And perfect format for my half page size shipping labels.",
  },
  {
    rating: 5,
    title: "ShippyShapShap!",
    name: "Ibrek",
    comment: (
      <div>
        I went to ship a package the other day and they wanted to charge me
        almost $70 at the store for sending a box cross country. Thank goodness
        I found this app, I was able to make the label right there. pay $1 for
        the print and ship it for less than $35.
        <br />
        <br />
        The tracking info all on my phone and the package even arrived a day
        before expected.
      </div>
    ),
  },
  {
    rating: 5,
    title: "What a shiptastic app!!!!!",
    name: "Zainmania",
    comment: (
      <div>
        Expedia in the late 90s revolutionized the booking industry, Netflix
        revolutionized entertainment streaming in the early 2000s and Uber
        forged a new industry and a model that is being replicated across many
        sectors in our daily life since 2010.
        <br />
        <br />
        Today, ShipShap has done the same for shipping. The industry disruptive
        shipping app we all need is finally here. Can you imagine being able to
        bypass the grumpy post office employees while also saving 80% on
        shipping? Well, this is what you will find in using ShipShap. No longer
        do you have to wait in line, pay an outrageous price on shipping, on top
        of being treated like you’re a nuisance to the post office staff! In
        less than 4 simple steps you’ll be able to quote and purchase a shipping
        label.
        <br />
        <br />
        You must try this app and I promise you will not regret it!
      </div>
    ),
  },
];

class LandingPage extends Component {
  state = {
    currentSlide: 0,
    showScroll: false,
    step: 0,
    testimonial: 0,
  };

  setSlide = (val) => {
    this.setState({
      currentSlide: val,
    });
  };

  setShowScroll = (val) => {
    this.setState({
      showScroll: val,
    });
  };
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.setState({
            testimonial: entry.target.index,
          });
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    },
    { threshold: [0.5, 1] },
  );

  componentDidMount = () => {
    const interval = setInterval(() => {
      this.setState({
        step: (this.state.step + 1) % 5,
      });
    }, 3000);

    const cards = Array.from(
      document.querySelectorAll(".carousel-mob .testimonial-item"),
    );

    cards.forEach((el, idx) => {
      el.index = idx;
      this.observer.observe(el);
    });

    return () => clearInterval(interval);
  };

  getInitials = (name) => {
    if (name) {
      let accName = name;
      return (
        accName.substring(0, 1) +
        accName.substring(accName.indexOf(" ") + 1, accName.indexOf(" ") + 2)
      );
    } else return "AN";
  };

  scrollCarousel = () => {};

  renderReview = (stars, id) => {
    const list = [];
    for (let i = 0; i < 5; i++) {
      if (Math.ceil(stars) !== stars && i > Math.ceil(stars) - 1) {
        list.push(
          <svg
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={id + " " + i}
          >
            <defs>
              <linearGradient id={`grad${id}-${i}`}>
                <stop offset="0%" stop-color="#FFD700" />
                <stop offset="0%" stop-color="grey" />
              </linearGradient>
            </defs>
            <path
              fill={`url(#grad${id}-${i})`}
              d="M8.42927 1.1969C8.75622 0.190651 10.1798 0.190652 10.5067 1.1969L11.7975 5.16951C11.9437 5.61952 12.3631 5.9242 12.8363 5.9242H17.0133C18.0713 5.9242 18.5113 7.2781 17.6553 7.89999L14.276 10.3552C13.8932 10.6333 13.733 11.1263 13.8792 11.5763L15.17 15.5489C15.497 16.5552 14.3453 17.3919 13.4893 16.77L10.11 14.3148C9.72718 14.0367 9.20884 14.0367 8.82604 14.3148L5.44673 16.77C4.59076 17.3919 3.43907 16.5552 3.76602 15.5489L5.0568 11.5763C5.20302 11.1263 5.04284 10.6333 4.66004 10.3552L1.28073 7.89999C0.424764 7.2781 0.864672 5.9242 1.9227 5.9242H6.09976C6.57292 5.9242 6.99228 5.61952 7.13849 5.16951L8.42927 1.1969Z"
            />
          </svg>,
        );
      } else if (Math.ceil(stars) !== stars && i === Math.ceil(stars) - 1) {
        let fill = Math.round((stars % 1) * 10) * 10 + "%";
        list.push(
          <svg
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={id + " " + i}
          >
            <defs>
              <linearGradient id={`grad${id}-${i}`}>
                <stop offset={fill} stopColor="#FFD700" />
                <stop offset={fill} stopColor="grey" />
              </linearGradient>
            </defs>
            <path
              fill={`url(#grad${id}-${i})`}
              d="M8.42927 1.1969C8.75622 0.190651 10.1798 0.190652 10.5067 1.1969L11.7975 5.16951C11.9437 5.61952 12.3631 5.9242 12.8363 5.9242H17.0133C18.0713 5.9242 18.5113 7.2781 17.6553 7.89999L14.276 10.3552C13.8932 10.6333 13.733 11.1263 13.8792 11.5763L15.17 15.5489C15.497 16.5552 14.3453 17.3919 13.4893 16.77L10.11 14.3148C9.72718 14.0367 9.20884 14.0367 8.82604 14.3148L5.44673 16.77C4.59076 17.3919 3.43907 16.5552 3.76602 15.5489L5.0568 11.5763C5.20302 11.1263 5.04284 10.6333 4.66004 10.3552L1.28073 7.89999C0.424764 7.2781 0.864672 5.9242 1.9227 5.9242H6.09976C6.57292 5.9242 6.99228 5.61952 7.13849 5.16951L8.42927 1.1969Z"
            />
          </svg>,
        );
      } else {
        list.push(<Star />);
      }
    }

    return (
      <div className="review">
        <div className="stars">{list}</div>
        <p className="info">{`${stars}-stars reviews on IOS App store `}</p>
      </div>
    );
  };

  renderTestimonialList = () => {
    const list = testimonials.map((item, id) => (
      <div
        key={id}
        className={`testimonial-item col-11 col-md-auto d-lg-flex flex-column align-items-start justify-content-start`}
      >
        {this.renderReview(item.rating, `Testimonial_${id}`)}
        <h5 className="title">{item.title}</h5>
        <p className="comment">{item.comment}</p>
        <div className="profile d-flex flex-row align-items-center">
          <div>
            <h5>{`- ${item.name}`}</h5>
          </div>
        </div>
      </div>
    ));

    return list;
  };

  renderMarqueeList = () => {
    const list = [];
    for (let i = 0; i < 10; i++) {
      list.push(
        <p key={i}>
          Ship from Africa! 60% off discount on both domestic and international.
          🎉🎊
        </p>,
      );
    }
    return list;
  };

  render() {
    return (
      <div
        className="landing scrollspy-landing"
        data-bs-spy="scroll"
        data-bs-target="#navbar-landing"
        data-bs-smooth-scroll="true"
        data-offset="50"
        tabIndex="0"
      >
        <section className="masthead" id="masthead">
          <div className="col-12 p-0 d-flex flex-column flex-lg-row ">
            <div className="content col-12 col-lg-5 d-flex flex-column justify-content-start justify-content-lg-center align-items-start order-1 order-lg-0 ">
              <div className="main">
                <h1>
                  The One-stop Shop
                  <br />
                  for All Your Shipping
                  <br /> Needs
                </h1>
                <p className="info">
                  We streamline the order fulfillment process for you and
                  connect you with the best shipping options, from importing
                  your orders, to printing a label, to tracking: all from the
                  convenience of your device. Shipping from Africa and beyond
                  has never been easier
                </p>
              </div>
              <div className="action d-flex flex-row align-items-center">
                <Link to={ROUTES.HOME + ROUTES.DASHBOARD}>
                  <button>Try It Now</button>
                </Link>
              </div>
              {this.renderReview(4.8, "App")}
            </div>
            <div className="image col-12 col-lg-7 order-0 order-lg-1">
              <img src={masthead} alt="dashboard" />
            </div>
          </div>
        </section>

        {/* <section className="partners d-flex justify-content-center">
          <div className="col-12 col-lg-8 p-0 d-flex flex-row justify-content-around align-items-center">
            <img src={DHL} alt="DHL"/>
            <img src={USPS} alt="USPS"/>
            <img src={ETHIO} alt="Ethiopian Airlines Cargo"/>
          </div>
        </section> */}

        <section
          className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center"
          id="shops"
        >
          <div className="col-12 col-lg-6 p-0 m-auto d-flex justify-content-center align-items-center">
            <img src={integrations} alt="integrations" />
          </div>
          <div className="content col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start">
            <h1>
              {" "}
              Simplify your shipping with
              <br />
              Shop Integrations.
            </h1>
            <p className="info">
              With a single click integrate your shop and import your orders to
              simplify and streamline your operations.{" "}
            </p>
            <Link
              to={ROUTES.MORE_SHOP}
              className="button d-none d-lg-flex justify-content-center align-items-center"
            >
              Explore Shop Integrations
            </Link>
            <Link
              to={ROUTES.MORE_SHOP}
              className="button d-flex d-lg-none justify-content-center align-items-center m-auto"
            >
              Explore Shops
            </Link>
          </div>
        </section>

        <section
          className="what-you-get col-12 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-end"
          id="what-you-get"
        >
          <div className="image col-12 col-lg-6 order-lg-1 d-flex">
            <img src={what_you_get} alt="what you get" />
            <div className="text">
              We’ve got your back,
              <br />
              every step of the way.
            </div>
          </div>
          {/* <div className="image col-12 col-lg-6 order-lg-1 d-flex d-lg-none">
            <img src={what_you_get_mob} alt="what you get"/>
          </div> */}
          <div className="content col-12 col-lg-6 order-lg-0">
            <div>
              <h1>What Makes Us Stand Out?</h1>
              <p className="info">
                Shipping peace of mind. We provide step by step guidance along
                with rates to transform your shipping experience.
              </p>
            </div>
            <div className="list col-12 col-md-7 col-lg-12 ">
              <div className="list-item">
                <Service1 />
                <p className="head">
                  Import Orders from all your sales channels.
                </p>
                <p className="info">
                  Manage all your order import and fulfillment needs from one
                  dashboard.{" "}
                </p>
              </div>
              <div className="list-item">
                <Service2 />
                <p className="head">Never lose a package again.</p>
                <p className="info">
                  We provide address validation and international insurance to
                  make sure you’ll never lose a package again.{" "}
                </p>
              </div>
              <div className="list-item">
                <Service3 />
                <p className="head">Track any shipment, anywhere.</p>
                <p className="info">
                  Easily access and share realtime updates and advanced tracking
                  on any shipment.{" "}
                </p>
              </div>
              <div className="list-item">
                <Service4 />
                <p className="head">No hidden fees.</p>
                <p className="info">
                  Get an accurate rate immediately at checkout so you know
                  exactly how much shipping will cost you.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center"
          id="apis"
        >
          <div className="col-12 col-lg-6 p-0 m-auto d-flex justify-content-center align-items-center">
            <img src={apis} alt="integrations" />
          </div>
          <div className="content col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start">
            <h1>
              Simple & Effective
              <br />
              API Integration
            </h1>
            <p className="info">
              Streamline your entire order fulfillment process, with our quick
              and easy API integrations that bring all your essentials into one
              place.{" "}
            </p>
            <Link
              to={ROUTES.MORE_API}
              className="button d-flex justify-content-center align-items-center"
            >
              Explore APIs
            </Link>
          </div>
        </section>

        <section
          className="mobile-cta col-12 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between"
          id="mobile-cta"
        >
          <div className="content col-12 col-lg-5">
            <h1>
              Fulfill all your
              <br /> orders
            </h1>
            <div>
              <ul className="steps d-flex flex-column align-items-center align-items-lg-start">
                <li
                  className={`step-item ${this.state.step === 0 ? "active" : ""}`}
                >
                  <p className="title">Sign Up and import your orders</p>
                  <p className="desc">
                    Signing up on ShipShap is easy to use. All you need is your
                    email address, and your basic information.
                  </p>
                </li>
                <li
                  className={`step-item ${this.state.step === 1 ? "active" : ""}`}
                >
                  <p className="title">Create Shipping Label</p>
                  <p className="desc">
                    Quickly and seamlessly create labels for your packages.
                  </p>
                </li>
                <li
                  className={`step-item ${this.state.step === 2 ? "active" : ""}`}
                >
                  <p className="title">Select Shipping Option</p>
                  <p className="desc">
                    Review discounted rates, with no hidden fees.
                  </p>
                </li>
                <li
                  className={`step-item ${this.state.step === 3 ? "active" : ""}`}
                >
                  <p className="title">Print and Pay</p>
                  <p className="desc">
                    Pay securely, and manage your payment method easily with
                    ShipShap.
                  </p>
                </li>
                <li
                  className={`step-item ${this.state.step === 4 ? "active" : ""}`}
                >
                  <p className="title">End-to-End Shipment Tracking</p>
                  <p className="desc">
                    Tracking and notifications to keep you and your customers
                    up-to-date.
                  </p>
                </li>
              </ul>
            </div>
            <div className="buttons d-none d-lg-flex flex-row align-items-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.shipshap"
                className=""
              >
                <img src={playStore} className="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
                className=""
              >
                <img src={appStore} className="" />
              </a>
            </div>
          </div>
          <div className="image col-12 col-lg-7 p-0 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
            <div className="col-12 p-0">
              <img
                src={signup_mobile_0}
                alt={"start shipping"}
                className={`${this.state.step === 0 ? "active" : ""}`}
              />
              <img
                src={signup_mobile_1}
                alt={"start shipping"}
                className={`${this.state.step === 1 ? "active" : ""}`}
              />
              <img
                src={signup_mobile_2}
                alt={"start shipping"}
                className={`${this.state.step === 2 ? "active" : ""}`}
              />
              <img
                src={signup_mobile_3}
                alt={"start shipping"}
                className={`${this.state.step === 3 ? "active" : ""}`}
              />
              <img
                src={signup_mobile_4}
                alt={"start shipping"}
                className={`${this.state.step === 4 ? "active" : ""}`}
              />
            </div>
            <div className="dots col-5 flex-row justify-content-between align-items-center d-flex d-lg-none">
              <div className={`${this.state.step === 0 ? "active" : ""}`}></div>
              <div className={`${this.state.step === 1 ? "active" : ""}`}></div>
              <div className={`${this.state.step === 2 ? "active" : ""}`}></div>
              <div className={`${this.state.step === 3 ? "active" : ""}`}></div>
              <div className={`${this.state.step === 4 ? "active" : ""}`}></div>
            </div>
            <div className="buttons d-flex d-lg-none flex-row align-items-center justify-content-between">
              <a
                href="https://play.google.com/store/apps/details?id=com.shipshap"
                className=""
              >
                <img src={playStore} className="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
                className=""
              >
                <img src={appStore} className="" />
              </a>
            </div>
          </div>
        </section>

        <section
          className="col-12 testimonials d-flex flex-column align-items-center"
          id="testimonials"
        >
          <h1>Our Customers Are Talking</h1>
          <p className="info">
            Read testimonials from users like you all over the World.
          </p>
          <div className="carousel col-11 p-0 d-none d-lg-flex flex-row align-items-center">
            <div className="carousel-list">{this.renderTestimonialList()}</div>
          </div>
          <div className="carousel-mob d-flex d-lg-none">
            <div className="carousel-list">{this.renderTestimonialList()}</div>
            <div className="dots col-5 d-flex d-lg-none m-auto ">
              {testimonials.map((el, idx) => {
                return (
                  <div
                    className={`dot ${this.state.testimonial === idx ? "active" : ""}`}
                  ></div>
                );
              })}
            </div>
          </div>
        </section>

        <section
          className="get-started col-12 d-flex flex-column align-items-center"
          id="get-started"
        >
          <h1>Tailored to Your Shipping Needs</h1>
          <p className="info">
            Leave the logistics to us. ShipShap has everything you need for
            professional-grade shipping whether you want to grow your business
            globally, or reach those in your community.{" "}
          </p>
          <div className="plans col-12 col-lg-7 ">
            <div className="plan-card">
              <div className="icon">
                <FiUsers />
              </div>
              <div className="banner"></div>
              <div className="content">
                <h3>
                  Shipshap for
                  <br /> Small Businesses
                </h3>
                <ul>
                  <li>
                    <FiCheck />
                    <div>
                      Get attractive shipping rates on every order, from
                      domestic to International.{" "}
                    </div>
                  </li>
                  <li>
                    <FiCheck />
                    <div>
                      With a single click integrate your shop with our
                      platform.{" "}
                    </div>
                  </li>
                  <li>
                    <FiCheck />
                    <div>
                      Solve all your shipping issues: we’re an integrated
                      platform that manages the logistics so you don’t have
                      to.{" "}
                    </div>
                  </li>
                  <li>
                    <FiCheck />
                    <div>
                      No hidden fees: get accurate rates immediately at
                      checkout.{" "}
                    </div>
                  </li>
                </ul>
                <Link to={ROUTES.HOME + ROUTES.DASHBOARD}>
                  <button className="col-12 p-0">Get started for free</button>
                </Link>
              </div>
            </div>
            <div className="plan-card">
              <div className="icon">
                <FiHome />
              </div>
              <div className="banner"></div>
              <div className="content">
                <h3>
                  Shipshap for
                  <br /> Enterprise
                </h3>
                <ul>
                  <li>
                    <FiCheck />
                    <div>
                      Manage your business across countries and carriers; all on
                      one platform.{" "}
                    </div>
                  </li>
                  <li>
                    <FiCheck />
                    <div>
                      Connect ShipShap to your existing stores and platforms
                      with our integrations and APIs.
                    </div>
                  </li>
                  <li>
                    <FiCheck />
                    <div>
                      We’re a call away to solving all your shipping issues.
                      Just contact us.
                    </div>
                  </li>
                  <li>
                    <FiCheck />
                    <div>
                      Optimize, automate, and customize your shipping workflow
                      to provide the best service, minus the up-keep.
                    </div>
                  </li>
                </ul>
                <a href="mailto:hello@shipshap.co">
                  <button className="col-12 p-0">Contact Our Team</button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          className="map col-12 d-flex flex-column align-items-center"
          id="map"
        >
          <BGDOTS className="bg-dots d-none d-lg-flex" />
          <h1>From Anywhere to Everywhere</h1>
          <p className="info">
            We give you the peace of mind to know your package will make it
            there safely and on time, anywhere from domestic to
            international.{" "}
          </p>
          <div className="world-map">
            <WorldMap />
          </div>

          <div className="cta-container col-12">
            <div
              className="call-to-action col-12 d-flex flex-column flex-lg-row align-items-center"
              id="cta"
            >
              <div className="content col-lg-6 ">
                <h1>
                  Explore The Best <br /> Shipping Options
                </h1>
                <div className="buttons d-flex flex-column flex-lg-row align-items-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.shipshap"
                    className=""
                  >
                    <img src={playStore} className="" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
                    className=""
                  >
                    <img src={appStore} className="" />
                  </a>
                </div>
              </div>
              <div className="image col-12 col-lg-6 p-0 ">
                <img src={shipshap_visual} alt="shipshap-visual" />
              </div>
            </div>
          </div>
        </section>

        {/* {renderScrollTop()} */}
      </div>
    );
  }
}

export default LandingPage;
