import CONVERSION_FACTORS from "../../../assets/weight-conversion-factors.json";
import {
  CUSTOM_PACKAGE_I,
  FLAT_RATE_PACKAGE_I,
  SESSION_AUTH,
} from "../../../constants/types";
import moment from "moment";
import * as ROUTES from "../../../constants/routes";

export const convertToWeightUnit = (value, fromUnit, toUnit) => {
  return (
    parseFloat(value) *
    CONVERSION_FACTORS[fromUnit.toString().toLowerCase().trim()][
      toUnit.toString().toLowerCase().trim()
    ]
  );
};

export const getTotalWeightInUnit = (parcels, toUnit) => {
  let totalWeight = 0;
  if (parcels?.parcels?.length > 0) {
    parcels.parcels.forEach((item) => {
      const quantity = parseInt(item.quantity) || 1;
      totalWeight += convertToWeightUnit(
        parseFloat(item.weight) * quantity,
        item.weight_unit,
        toUnit,
      );
    });
  } else if (parcels.selectedWeight?.weight) {
    const quantity = parseInt(parcels.selectedParcel?.quantity) || 1;
    totalWeight = convertToWeightUnit(
      parseFloat(parcels.selectedWeight.weight) * quantity,
      parcels.selectedWeight.weight_unit,
      toUnit,
    );
  }

  return totalWeight;
};

export const missingDetails = (details) => {
  let missing = false;
  if (!details || Object.keys(details).length === 0) {
    return true;
  }
  Object.keys(details).forEach((field, idx) => {
    let item = details[field];
    if ((field !== "is_verified" && !item) || item.toString().trim() === "") {
      missing = missing || true;
    }
  });
  return missing;
};

export const validatePackage = (
  selectedParcel,
  selectedWeight,
  packageType,
) => {
  let valid = true;
  const { length, width, height } = selectedParcel;
  const errors = {};
  let text = "";
  if (packageType === CUSTOM_PACKAGE_I) {
    if (!length || length <= 0) errors.length = true;
    if (!width || width <= 0) errors.width = true;
    if (!height || height <= 0) errors.height = true;
    if (errors.length || errors.width || errors.height) {
      text += "Dimensions";
      valid = false;
    }
  } else if (packageType === FLAT_RATE_PACKAGE_I && !selectedParcel.template) {
    errors.template = true;
    text += "Dimensions";
    valid = false;
  }
  if (!selectedWeight.weight || selectedWeight.weight <= 0) {
    errors.weight = true;
    text += text ? " and Weight" : "Weight";
    valid = false;
  }

  return { valid, errors, message: `Please enter a valid ${text} value` };
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const isDomesticKenyanShipment = (address_from, address_to) => {
  if (!address_from?.country || !address_to?.country) return false;
  return address_from.country === address_to.country;
};

export const toMoneyFormat = (value, currency) => {
  let options = currency
    ? { style: "currency", currency: currency }
    : { maximumSignificantDigits: 3 };
  let formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(value);
};

export const getQuantity = (item) => {
  if (!item) return 1;
  const feature = item.variation?.features?.find(
    (feature) => feature.feature_type == "quantity",
  );
  if (feature) {
    return parseInt(feature.value) * parseInt(item.quantity);
  }
  return item.quantity;
};

export const tableDateFormatter = (cell) => {
  const key = "date-column-value";
  return !cell ? (
    <div key={key}>-</div>
  ) : (
    <p
      key={key}
    >{`${moment(new Date(cell)).format("MMM D, YYYY [\r\n] hh:mm A")}`}</p>
  );
};

export const dateFilter = (cell, row) => {
  return !cell
    ? null
    : `${moment(new Date(cell)).format("MMM D, YYYY hh:mm A")}`;
};

export const getAddress = (address) => {
  const street2 = `${address.street2 ? address.street2 + ", " : ""}`;
  return `${address.street1}, ${street2}${address.city}, ${address.zip_code}, ${address.country}`;
};

const levenshteinDistance = (a, b) => {
  const dp = Array.from({ length: a.length + 1 }, (_, i) =>
    Array(b.length + 1).fill(0),
  );
  for (let i = 0; i <= a.length; i++) dp[i][0] = i;
  for (let j = 0; j <= b.length; j++) dp[0][j] = j;

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      if (a[i - 1] === b[j - 1]) dp[i][j] = dp[i - 1][j - 1];
      else
        dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]) + 1;
    }
  }
  return dp[a.length][b.length];
};

export const findBestMatch = (searchString, strings) => {
  return strings.reduce(
    (best, current) => {
      const distance = levenshteinDistance(searchString, current);
      return distance < best.distance ? { value: current, distance } : best;
    },
    { value: null, distance: Infinity },
  ).value;
};

const routeToRuleMapping = {
  [ROUTES.WALLETS]: process.env?.REACT_APP_WALLET_REDIRECT ?? "dev",
  [ROUTES.MANIFESTS]: process.env?.REACT_APP_MANIFEST_REDIRECT ?? "dev",
  [ROUTES.LABEL]: process.env?.REACT_APP_LABELS_REDIRECT ?? "dev",
  [ROUTES.ORDERS]: process.env?.REACT_APP_ORDERS_REDIRECT ?? "dev",
};

export const isWalletAccount = (authType, email) => {
  const walletAccounts =
    process.env.REACT_APP_WALLET_ACCOUNTS?.toString().split(";") || [];

  return authType === SESSION_AUTH || walletAccounts.includes(email);
};

export const isAllowedRedirect = (route) => {
  const ENV = process.env?.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";
  const getAllowedEnv = routeToRuleMapping[route];

  if (getAllowedEnv === "none") {
    return false;
  }
  if (getAllowedEnv === "local") {
    return !ENV || !["production", "development", "preview"].includes(ENV);
  }

  return ENV !== "production" || getAllowedEnv === "prod";
};

const routeToNewRouteMapping = {
  [ROUTES.WALLETS]: "wallet",
  [ROUTES.MANIFESTS]: "manifests",
  [ROUTES.LABEL]: "labels",
  [ROUTES.ORDERS]: "orders",
};

export const getRedirectURL = (route, webToken, authType) => {
  const newDashboardURL = process.env?.REACT_APP_DASHBOARD_URL
    ? process.env.REACT_APP_DASHBOARD_URL
    : `http://localhost:3003`;
  let tokenParam = "";
  if (authType !== SESSION_AUTH) {
    tokenParam = `&webToken=${webToken}`;
  }
  const redirectURL = `${newDashboardURL}/api/redirect?redirectTo=${routeToNewRouteMapping[route]}${tokenParam}`;
  if (isAllowedRedirect(route) && (authType === SESSION_AUTH || webToken)) {
    return redirectURL;
  }
  return ROUTES.HOME + route;
};
