import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Spinner } from "react-bootstrap";
import {
  FiAlertCircle,
  FiChevronLeft,
  FiFile,
  FiFilePlus,
  FiImage,
  FiUploadCloud,
  FiCheck,
} from "react-icons/fi";
import history from "../../../History";
import * as ROUTES from "../../../constants/routes";
import {
  updateBusinessForm,
  uploadBusinessDocument,
} from "../../actions/BusinessActions";

const BusinessRegistrationForm = (props) => {
  const [dragActive, setDragActive] = useState(false);
  const [document, setDocument] = useState({
    name: "",
    size: "",
  });

  useEffect(() => {
    if (props.details) {
      const details = {};
      if (
        (props.businessDetailsForm?.document_name !== "" ||
          props.details?.document_name !== "") &&
        (props.businessDetailsForm?.document_size?.toString().trim() !== "" ||
          props.details?.document_size?.toString().trim() !== "")
      ) {
        details["uploading"] = true;
        details["progress"] = 1;
      }
      props.updateBusinessForm(details);
      setDocument({
        name:
          props.businessDetailsForm?.document_name ||
          props.details?.document_name ||
          "",
        size:
          props.businessDetailsForm?.document_size ||
          props.details?.document_size ||
          "",
      });
    }
  }, []);

  const checkValidFile = (file) => {
    if (!file) return false;
    if (
      file.type !== "application/pdf" &&
      file.type !== "image/png" &&
      file.type !== "image/jpeg"
    ) {
      props.updateBusinessForm({ errors: "File type not supported" });
      return false;
    }
    if (file.size > 1000 * 1000 * 40) {
      props.updateBusinessForm({ errors: "File size cannot exceed 40MB" });
      return false;
    }
    return true;
  };

  const handleFile = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    if (checkValidFile(file)) {
      setDocument(file);
      props.uploadBusinessDocument(file);
    }

    e.target.value = "";
  };

  const handleDrag = (e) => {
    e.preventDefault();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];

    if (checkValidFile(file)) {
      setDocument(file);
      props.uploadBusinessDocument(file);
    }
  };

  const handleNext = () => {
    props.updateBusinessForm({ errors: "" });
    history.push(ROUTES.TAX_INFO);
  };

  const handleSubmit = () => {
    if (
      (props.businessDetailsForm.document_name === "" ||
        props.businessDetailsForm.document_size === "") &&
      (!props.details?.document_name || !props.details?.document_size)
    ) {
      props.updateBusinessForm({ errors: "Please upload a document" });
      return;
    }
    handleNext();
  };

  const getSizeString = (bytes) => {
    let size = bytes;
    let unit = "B";
    if (size > 1024) {
      size = (size / 1000).toFixed(1);
      unit = " KB";
    }
    if (size > 1024) {
      size = (size / 1000).toFixed(1);
      unit = " MB";
    }
    return size.toString() + unit;
  };

  return (
    <>
      <h3>Proof of business registration</h3>

      {props.businessDetailsForm.errors && (
        <div className={"error-block"}>
          <FiAlertCircle />
          <p>{props.businessDetailsForm.errors}</p>
        </div>
      )}
      <div
        className={
          "form col-12 d-flex flex-column align-items-center text-left"
        }
      >
        <label className={"col-12 p-0 d-flex flex-column"}>
          Document Type
          <Dropdown>
            <Dropdown.Toggle>
              {props.businessDetailsForm.document_type || "Select an Option"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  props.updateBusinessForm({ type: "Business License" })
                }
              >
                Business License
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </label>
        <label
          htmlFor={"file"}
          className={`file-upload ${dragActive ? "active" : ""} ${props.businessDetailsForm.errors ? "error" : ""}`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            id={"file"}
            name={"file"}
            type={"file"}
            accept={".jpg,.png,.pdf"}
            onChange={handleFile}
            multiple={false}
          />
          {dragActive ? (
            <div
              className={
                "content drag d-flex flex-column justify-content-around align-items-center"
              }
            >
              <div className={"icon"}>
                <FiFilePlus size={45} />
              </div>
              <p className={"mb-0"}>
                <span>Drop file to upload</span>
              </p>
            </div>
          ) : (document.name || props.businessDetailsForm.document_name) &&
            (document.size || props.businessDetailsForm.document_size) &&
            props.businessDetailsForm.uploading ? (
            <div className={"content file"}>
              <FiFile />
              <div>
                <div
                  className={
                    "col-12 p-0 d-flex flex-row align-items-center justify-content-between"
                  }
                >
                  <p className={"doc-name"}>
                    {props.businessDetailsForm.uploading && document.name
                      ? document.name
                      : props.businessDetailsForm.document_name}
                  </p>
                  {props.businessDetailsForm.progress === 1 ? (
                    <FiCheck className={`uploaded`} />
                  ) : (
                    <Spinner animation={"border"} />
                  )}
                </div>
                <p className={"doc-size"}>
                  {getSizeString(
                    props.businessDetailsForm.uploading && document.size
                      ? document.size
                      : props.businessDetailsForm.document_size,
                  )}
                </p>
                <div
                  className={
                    "d-flex flex-row align-items-center justify-content-between"
                  }
                >
                  <div className={"progress-bar"}>
                    <div
                      className={`progress-bar-filler`}
                      style={{
                        width: `calc( 100% * ${props.businessDetailsForm.progress} )`,
                      }}
                    ></div>
                  </div>
                  <p>{`${props.businessDetailsForm.progress * 100}%`}</p>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                "content empty d-flex flex-column justify-content-around align-items-center"
              }
            >
              <div className={"icon"}>
                <FiUploadCloud size={45} />
              </div>
              <p className={"mb-0"}>
                <span>Click to upload&nbsp;</span>or drag and drop
              </p>
            </div>
          )}
        </label>
      </div>
      <div
        className={
          "buttons col-12 d-flex flex-column align-items-center text-left"
        }
      >
        <button
          onClick={handleSubmit}
          disabled={props.businessDetailsForm.loading}
        >
          {props.businessDetailsForm.loading ? (
            <Spinner animation={"border"} role={"status"} />
          ) : (
            "Continue"
          )}
        </button>
        <a className={"skip"} onClick={handleNext}>
          Skip
        </a>
      </div>
    </>
  );
};

const mapStateToProps = ({
  businessDetailsForm,
  businessDetails,
  applicationContext,
}) => ({
  businessDetailsForm,
  details: businessDetails.details,
  context: applicationContext,
});

export default connect(mapStateToProps, {
  updateBusinessForm,
  uploadBusinessDocument,
})(BusinessRegistrationForm);
