import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import history from "../../../History";
import { FiAlertCircle, FiChevronLeft } from "react-icons/fi";
import * as ROUTES from "../../../constants/routes";
import {
  saveBusinessDetails,
  updateBusinessForm,
} from "../../actions/BusinessActions";
import { clearApplicationContext } from "../../actions/ApplicationContextActions";

const TaxInfoForm = (props) => {
  const [error, setError] = useState("");

  const handleNext = (type = "complete") => {
    if (props.actionType !== "edit" && type === "complete") props.complete();
    else {
      history.replace(-2);
      history.replace(props.returnURL);
      props.clearApplicationContext();
    }
  };

  const handleSubmit = () => {
    const { tax_number } = props.businessDetailsForm;
    if (!tax_number || tax_number.toString().trim() === "") {
      setError("Business Tax Number cannot be empty");
      return;
    }
    const data = {
      tax_number,
    };
    props.saveBusinessDetails(data, handleNext);
  };

  useEffect(() => {
    if (
      props.details &&
      props.details.tax_number !== "" &&
      props.businessDetailsForm.tax_number === ""
    ) {
      props.updateBusinessForm({ tax_number: props.details.tax_number });
    }
  }, []);

  return (
    <>
      <h3>Tax number</h3>

      {props.businessDetailsForm.errors && (
        <div className={"error-block"}>
          <FiAlertCircle />
          <p>{props.businessDetailsForm.errors}</p>
        </div>
      )}
      <div
        className={
          "form col-12 d-flex flex-column align-items-center text-left"
        }
      >
        <label
          className={`col-12 p-0 d-flex flex-column ${error ? "error" : ""}`}
        >
          Business Tax Number
          <input
            placeholder={"Enter your business tax identification number"}
            value={props.businessDetailsForm.tax_number}
            onChange={(e) => {
              setError("");
              props.updateBusinessForm({ tax_number: e.target.value });
            }}
          />
          <p className={"error"}>{error}</p>
        </label>
      </div>
      <div className={"buttons col-12 d-flex flex-column align-items-center"}>
        <button onClick={handleSubmit}>
          {props.businessDetailsForm.loading ? (
            <Spinner animation={"border"} role={"status"} />
          ) : (
            "Continue"
          )}
        </button>
        <a className={"skip"} onClick={() => handleNext("skip")}>
          Skip
        </a>
      </div>
    </>
  );
};

const mapStateToProps = ({
  businessDetailsForm,
  applicationContext,
  businessDetails,
}) => ({
  businessDetailsForm,
  details: businessDetails.details,
  returnURL: applicationContext.context.returnURL,
  actionType: applicationContext.context.actionType,
});

export default connect(mapStateToProps, {
  updateBusinessForm,
  saveBusinessDetails,
  clearApplicationContext,
})(TaxInfoForm);
